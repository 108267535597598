import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { Ip } from '../ip.entity';
import { IpDetails } from '../ip-details.value-object';

@EntityRelationship({
	sourceEntity: Ip,
	dataEntity: IpDetails,
	fixedData: { lookingBackInDays: 30 },
	allowedTypes: [RelationshipType.OneToOne],
	cache: {
		time: 1000 * 60, // 1 minute
		max: 10,
	},
	baseUrl: (config, query) => {
		return query && query.where && query.where['vNextApi'] ? config.data.serviceUrls.entityPagesService + '/ips' : config.data.serviceUrls.threatIntel;
	},
	endpoint: 'GeoLocation',
	allItemsEndpointTrailingSlash: false,
	getRelationshipData: (ip: Ip) => ({
		ip: ip.id,
	}),
})
export class IpIpDetailsRelationship implements EntityRelationshipRepositoryType<Ip, IpDetails> {}
