var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Ip, IpIpStatsRelationship } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { EntityPrevalenceWidgetBase } from '../../../../global_entities/components/entity-widgets/prevalence/entity-prevalence.widget.base';
import { I18nService } from '@wcd/i18n';
import { TimeRangesService } from '../../../../shared/services/time-ranges.service';
import { FeaturesService } from '@wcd/config';
var IpPrevalenceWidgetComponent = /** @class */ (function (_super) {
    __extends(IpPrevalenceWidgetComponent, _super);
    function IpPrevalenceWidgetComponent(paris, activatedEntity, reportsService, i18nService, timeRangeService, featuresService) {
        var _this = _super.call(this, paris, activatedEntity, reportsService, i18nService, timeRangeService, featuresService) || this;
        _this.id = 'ip-prevalence-widget';
        _this.entityType = Ip;
        _this.entityStatisticsRelationship = IpIpStatsRelationship;
        _this.dataQuery = {};
        return _this;
    }
    return IpPrevalenceWidgetComponent;
}(EntityPrevalenceWidgetBase));
export { IpPrevalenceWidgetComponent };
