import { MailMessageUACPending } from '@wcd/domain';
import { Lazy } from '../../../utils/lazy';
import { FabricIconNames } from '@wcd/scc-common';
var MailMessageUACPendingEntityTypeService = /** @class */ (function () {
    function MailMessageUACPendingEntityTypeService() {
        this._entityType = new Lazy(function () { return ({
            entity: MailMessageUACPending,
            id: 'mailMessageUACPending',
            icon: FabricIconNames.Mail,
        }); });
    }
    Object.defineProperty(MailMessageUACPendingEntityTypeService.prototype, "entityType", {
        get: function () {
            return this._entityType.value;
        },
        enumerable: true,
        configurable: true
    });
    return MailMessageUACPendingEntityTypeService;
}());
export { MailMessageUACPendingEntityTypeService };
