var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FileFileStatsRelationship, FileTopFileNamesApiCall } from '@wcd/domain';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { Paris } from '@microsoft/paris';
import { filter, map, startWith } from 'rxjs/operators';
import { TitleService } from '../../../shared/services/title.service';
import { Feature, FeaturesService } from '@wcd/config';
import { ActivatedRoute, Router } from '@angular/router';
import { FilesService } from '../services/files.service';
var FileEntityComponent = /** @class */ (function (_super) {
    __extends(FileEntityComponent, _super);
    function FileEntityComponent(i18nService, titleService, paris, featuresService, router, route, filesService) {
        var _this = _super.call(this) || this;
        _this.i18nService = i18nService;
        _this.titleService = titleService;
        _this.paris = paris;
        _this.featuresService = featuresService;
        _this.router = router;
        _this.route = route;
        _this.filesService = filesService;
        _this.featuresChangeSubscription = _this.featuresService.featureChanged$
            .pipe(filter(function (_a) {
            var featureId = _a.featureId;
            return featureId === Feature.UpgradeFilePage;
        }), filter(function (_) { return !featuresService.isEnabled(Feature.UpgradeFilePage); }))
            .subscribe(function () {
            var params = _this.route.snapshot.queryParams;
            _this.router.navigateByUrl(_this.filesService.getLegacyFileLink(_this.file.id, new Date((params && params['to']) || Date.now())));
        });
        return _this;
    }
    Object.defineProperty(FileEntityComponent.prototype, "file", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    FileEntityComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isNewFilePageEnabled = this.featuresService.isEnabled(Feature.NewFilePage);
        // TODO - always use FileTopFileNamesApiCall once FilePrevalenceAndTopNamesNewApi fully enabled.
        var topFileNamesCount$ = this.featuresService.isEnabled(Feature.FilePrevalenceAndTopNamesNewApi)
            ? this.paris
                .apiCall(FileTopFileNamesApiCall, this.file)
                .pipe(map(function (topFileNames) { return topFileNames && topFileNames.length; }))
            : this.paris
                .getRelatedItem(FileFileStatsRelationship, this.file, { where: { filesPrefix: this.featuresService.isEnabled('K8SMigration-EPSFilePrevalence-kw') } })
                .pipe(map(function (fileStats) { return fileStats && fileStats.topFileNames.length; }));
        this.tabs$ = topFileNamesCount$.pipe(map(function (topFileNamesLength) { return _this.getTabs(topFileNamesLength); }), startWith(this.getTabs()));
        this.titleService.setState({
            pageTitle: "File: " + (this.file.name || this.file.sha1),
        });
    };
    FileEntityComponent.prototype.ngOnDestroy = function () {
        this.featuresChangeSubscription && this.featuresChangeSubscription.unsubscribe();
    };
    FileEntityComponent.prototype.getTabs = function (topFileNamesLength) {
        var tabs = [
            !this.featuresService.isEnabled(Feature.IncidentsQEntityPages) &&
                {
                    id: 'alerts',
                    name: this.isNewFilePageEnabled ? this.i18nService.get('machines.tabs.incidentsAndAlerts') : this.i18nService.get('machines.tabs.alerts'),
                    routerLink: './alerts',
                },
            this.featuresService.isEnabled(Feature.IncidentsQEntityPages) &&
                {
                    id: 'incidents',
                    name: this.i18nService.get('machines.tabs.incidentsAndAlerts'),
                    routerLink: './incidents',
                },
            {
                id: 'timeline',
                name: this.i18nService.get('file.fileInOrg'),
                routerLink: './timeline',
            },
            {
                id: 'deepAnalysis',
                name: this.i18nService.get('deepAnalysis.title'),
                routerLink: './deep_analysis',
            },
            {
                id: 'fileNames',
                name: this.i18nService.get('file.fileNames'),
                routerLink: './file_names',
                value: topFileNamesLength,
            },
        ].filter(Boolean);
        this.isNewFilePageEnabled && tabs.push({
            id: 'actionCenter',
            name: this.i18nService.get('main_navigation_menu_item_action_center'),
            routerLink: './action_center',
        });
        tabs.unshift({
            id: 'overview',
            name: this.i18nService.get('machines.tabs.overview'),
            routerLink: './overview',
        });
        return tabs.map(function (tab) { return new TabModel(tab); });
    };
    return FileEntityComponent;
}(EntityComponentBase));
export { FileEntityComponent };
