import { Entity, EntityModelBase } from '@microsoft/paris';

@Entity({
	singularName: 'mailMessageUACPending',
	pluralName: 'mailMessageUACPending',
	endpoint: 'mailboxes',
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class MailMessageUACPending extends EntityModelBase {}
