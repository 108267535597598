import { Component, OnInit } from '@angular/core';
import { File, FileFileStatsRelationship, FileStats, FileTopFileNamesApiCall } from '@wcd/domain';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { Paris } from '@microsoft/paris';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { TitleService } from '../../../shared/services/title.service';
import { Feature, FeaturesService } from '@wcd/config';
import { ActivatedRoute, Router } from '@angular/router';
import { FilesService } from '../services/files.service';

@Component({
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<div
				class="wcd-flex-none wcd-margin-xxLarge-horizontal wcd-flex-spaceBetween-horizontal
				wcd-flex-center-vertical wcd-margin-large-bottom wcd-padding-none-left-sm-screens tabs-z-index"
			>
				<ng-container *ngIf="(tabs$ | async) as tabs">
					<tabs [tabsData]="tabs" [currentRouteIsActive]="true"></tabs>
				</ng-container>
			</div>
			<div class="wcd-flex-1  ms-background-color-white">
				<router-outlet></router-outlet>
			</div>
		</div>
	`,
	styles: [
		`
			.tabs-z-index {
				z-index: 2;
			}
		`
	],
})
export class FileEntityComponent extends EntityComponentBase<File> implements OnInit {
	tabs$: Observable<ReadonlyArray<TabModel>>;
	featuresChangeSubscription: Subscription;
	isNewFilePageEnabled;
	constructor(
		private readonly i18nService: I18nService,
		private readonly titleService: TitleService,
		private readonly paris: Paris,
		private readonly featuresService: FeaturesService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly filesService: FilesService
	) {
		super();

		this.featuresChangeSubscription = this.featuresService.featureChanged$
			.pipe(
				filter(({ featureId }) => featureId === Feature.UpgradeFilePage),
				filter(_ => !featuresService.isEnabled(Feature.UpgradeFilePage))
			)
			.subscribe(() => {
				const params = this.route.snapshot.queryParams;
				this.router.navigateByUrl(
					this.filesService.getLegacyFileLink(
						this.file.id,
						new Date((params && params['to']) || Date.now())
					)
				);
			});
	}

	get file(): File {
		return this.entity;
	}

	ngOnInit() {
		this.isNewFilePageEnabled = this.featuresService.isEnabled(Feature.NewFilePage);
		// TODO - always use FileTopFileNamesApiCall once FilePrevalenceAndTopNamesNewApi fully enabled.
		const topFileNamesCount$ = this.featuresService.isEnabled(Feature.FilePrevalenceAndTopNamesNewApi)
			? this.paris
					.apiCall(FileTopFileNamesApiCall, this.file)
					.pipe(map((topFileNames: Array<string>) => topFileNames && topFileNames.length))
			: this.paris
					.getRelatedItem(
						FileFileStatsRelationship,
						this.file,
						{ where: { filesPrefix: this.featuresService.isEnabled('K8SMigration-EPSFilePrevalence-kw') } })
					.pipe(map((fileStats: FileStats) => fileStats && fileStats.topFileNames.length));

		this.tabs$ = topFileNamesCount$.pipe(
			map((topFileNamesLength?: number) => this.getTabs(topFileNamesLength)),
			startWith(this.getTabs()));

		this.titleService.setState({
			pageTitle: `File: ${this.file.name || this.file.sha1}`,
		});
	}

	ngOnDestroy() {
		this.featuresChangeSubscription && this.featuresChangeSubscription.unsubscribe();
	}


	private getTabs(topFileNamesLength?: number): Array<TabModel> {
		const tabs = [
			!this.featuresService.isEnabled(Feature.IncidentsQEntityPages) &&
			{
				id: 'alerts',
				name: this.isNewFilePageEnabled ? this.i18nService.get('machines.tabs.incidentsAndAlerts') : this.i18nService.get('machines.tabs.alerts'),
				routerLink: './alerts',
			},
			this.featuresService.isEnabled(Feature.IncidentsQEntityPages) &&
			{
				id: 'incidents',
				name: this.i18nService.get('machines.tabs.incidentsAndAlerts'),
				routerLink: './incidents',
			},
			{
				id: 'timeline',
				name: this.i18nService.get('file.fileInOrg'),
				routerLink: './timeline',
			},
			{
				id: 'deepAnalysis',
				name: this.i18nService.get('deepAnalysis.title'),
				routerLink: './deep_analysis',
			},
			{
				id: 'fileNames',
				name: this.i18nService.get('file.fileNames'),
				routerLink: './file_names',
				value: topFileNamesLength,
			},
		].filter(Boolean);

		this.isNewFilePageEnabled && tabs.push(	{
			id: 'actionCenter',
			name: this.i18nService.get('main_navigation_menu_item_action_center'),
			routerLink: './action_center',
		})

		tabs.unshift({
			id: 'overview',
			name: this.i18nService.get('machines.tabs.overview'),
			routerLink: './overview',
		});

		return tabs.map(tab => new TabModel(tab));

	}
}
