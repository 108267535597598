import { ComponentRef, EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { DataEntityType, EntityModelBase, Paris, ModelBase } from '@microsoft/paris';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PanelType, PanelSettings, PanelService } from '@wcd/panels';
import { EntityPanelComponent } from '../components/entity-panels/entity-panel.component';
import { GlobalEntityTypesService } from './global-entity-types.service';
import { ItemActionModel } from '../../dataviews/models/item-action.model';
import { EntityType } from '../models/entity-type.interface';
import {
	EntityTypeWithPanelSupport,
	openEntityPanel,
	openMcasUserPanel,
	panelService as AngularWrapperPanelService,
	panelSettingsToNavigationConfig,
	RoutesService,
} from '@wcd/shared';
import { sccHostService, isAccountLinkable  } from '@wcd/scc-interface';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';


const DEFAULT_PANEL_SETTINGS: PanelSettings = {
	type: PanelType.large,
	isModal: true,
	showOverlay: false,
	isBlocking: false,
	noBodyPadding: true,
	scrollBody: false,
};

@Injectable()
export class EntityPanelsService implements OnDestroy {
	private _currentlyDisplayedEntities: Map<DataEntityType, BehaviorSubject<Array<ModelBase>>> = new Map();
	private _entityPanels: Map<DataEntityType, ComponentRef<EntityPanelComponent>> = new Map();
	private _panelCreateSubscription: Subscription;
	private _panelActionSubscriptions: Map<ComponentRef<EntityPanelComponent>, Subscription> = new Map();
	private isUnifiedUserPane: boolean;

	onAction: EventEmitter<EntityPanelActionEvent> = new EventEmitter<EntityPanelActionEvent>();
	onCloseEntityPanel: EventEmitter<DataEntityType> = new EventEmitter<DataEntityType>();

	constructor(
		private panelsService: PanelService,
		private paris: Paris,
		private globalEntityTypesService: GlobalEntityTypesService,
		private routesService: RoutesService,
		public i18n: I18nService,
		private featureService: FeaturesService,
	) {
		this.routesService.getUnifiedUserPaneEnabledAsync().then(value=> this.isUnifiedUserPane = value);
	}

	showEntityById<T extends EntityModelBase<TId>, TId extends string = string>(
		entityConstructor: DataEntityType<T, any, string>,
		entityId: TId,
		options?: any,
		panelSettings?: PanelSettings
	): void {
		this.paris
			.getItemById(entityConstructor, entityId, null, options)
			.subscribe((entity: T) => this.showEntity(entityConstructor, entity, options, panelSettings));
	}

	showEntity<T extends EntityModelBase<string | number>>(
		entityConstructor: DataEntityType<T, any, string>,
		entity: T,
		options?: any,
		panelSettings?: PanelSettings,
		contextLog?: string
	): void {
		this.showEntities(entityConstructor, [entity], options, panelSettings, contextLog);
	}

	showEntities<T extends EntityModelBase<any>>(
		entityConstructor: DataEntityType<T, any, string>,
		entities: Array<T>,
		options?: any,
		panelSettings?: PanelSettings,
		contextLog?: string
	) {
		this._panelCreateSubscription && this._panelCreateSubscription.unsubscribe();

		const existingPanel = this._entityPanels.get(entityConstructor) as ComponentRef<EntityPanelComponent>;

		if (entities && entities.length) {
			const finalPanelSettings: PanelSettings = Object.assign(
				{
					id: `${entityConstructor.singularName.replace(/\s/g, '')}-entity-panel`,
				},
				DEFAULT_PANEL_SETTINGS,
				panelSettings
			);
			const entityType: EntityType<T> = this.globalEntityTypesService.getEntityType(<any>(
				entityConstructor
			));
			const isSingleEntity = entities.length === 1;

			if (isSingleEntity) {
				const entity = entities[0];
				const reactEntityTypeName = this.getReactEntityPanelType(entityType, entity);
				if (this.shouldOpenReactEntityPanel(reactEntityTypeName) || this.shouldOpenReactMcasUserPanel(reactEntityTypeName, entity)){
					this.openReactEntityPanel(entityType, reactEntityTypeName, entity, entityConstructor, panelSettings, contextLog);
					if (existingPanel) this.panelsService.closePanel(existingPanel);
					this.setCurrentlyDisplayedEntities(entityConstructor, entities);
					return;
				}
			}

			if (existingPanel) {
				// Close any panel that isn't the existing panel (otherwise we might change the entity in the panel, but it's going to be covered by a different panel):
				this.panelsService.closePanelsBy(panel => panel !== existingPanel.instance);

				existingPanel.instance.setContextLog(contextLog);
				existingPanel.instance.options = options;
				existingPanel.instance.setEntities(entities);
				existingPanel.instance.panel.setSettings(finalPanelSettings);
			} else {
				if (!entityType)
					throw new Error(
						`Can't show entities, EntityType for class '${
							entityConstructor.name
						}' isn't available.`
					);

				const entityPanelInputs: { [index: string]: any } = {
					entities: entities,
					entityType: entityType,
					options: options,
					contextLog: contextLog
				};
				this._panelCreateSubscription = this.panelsService
					.create(EntityPanelComponent, finalPanelSettings, entityPanelInputs)
					.subscribe((panel: ComponentRef<EntityPanelComponent>) => {
						this._entityPanels.set(entityConstructor, panel);
						this._panelActionSubscriptions.set(
							panel,
							panel.instance.actionRun.subscribe((action: ItemActionModel) => {
								this.onAction.emit({
									entityType: <any>entityType,
									action: action,
								});
							})
						);

						panel.onDestroy(() => {
							this._entityPanels.delete(entityConstructor);
							this.setCurrentlyDisplayedEntities(entityConstructor);
							this._panelActionSubscriptions.get(panel).unsubscribe();
							this._panelActionSubscriptions.delete(panel);
							this.onCloseEntityPanel.emit(entityConstructor);
						});
					});
			}
			this.setCurrentlyDisplayedEntities(entityConstructor, entities);
		} else {
			if (existingPanel) existingPanel.destroy();
		}
		sccHostService.isSCC && AngularWrapperPanelService.closeAllPanels(false); // Opening an angular panel closes all React panels
	}

	private shouldOpenReactEntityPanel<T>(entityTypeName: string) {
		const reactEntityPanelsInAngularEnabled = this.featureService.isEnabled(Feature.ReactEntityPanelsInAngular);
		let entitiesWithReactPanelSupport = ['alert', 'machine', 'file', 'ip', 'url', 'url-domain'];
		// Checking feature flag of UAC Email Entity flyout 
		if (this.featureService.isEnabled(Feature.ReactMdoEmailPanel)) {
			entitiesWithReactPanelSupport = entitiesWithReactPanelSupport.concat([
				'mailMessageUACPending',
				'mailMessageUACHistory',
			]);
		}
		if (this.featureService.isEnabled(Feature.ImprovedEntitySidePanels)) {
			entitiesWithReactPanelSupport = entitiesWithReactPanelSupport.concat(['process', 'fileInstance']);
			if (this.featureService.isEnabled(Feature.ImprovedRemediationStatus) || this.featureService.isEnabled(Feature.ImprovedRemediationStatusSilentMode)) {
				entitiesWithReactPanelSupport = entitiesWithReactPanelSupport.concat(['processEvidence', 'fileEvidence']);
			}
		}

		return sccHostService.isSCC && reactEntityPanelsInAngularEnabled && entitiesWithReactPanelSupport.includes(entityTypeName);
	}

	private shouldOpenReactMcasUserPanel<T>(entityTypeName: string, entity: any) {
		const { sid, aadUserId, accountName, domainName } = entity;
		return entityTypeName === "aaduser" && this.isUnifiedUserPane && isAccountLinkable({sid, accountName, accountDomainName: domainName, aadUserId})
	}

	private getReactEntityPanelType(entityType: EntityType<any>, entity: any): string {
		if (!entityType) {
			return 'NotSupported';
		}
		const fileInstanceAndProcessSidePanelsEnabled = this.featureService.isEnabled(Feature.ImprovedEntitySidePanels);
		const improvedRemediationStatusEnabled = this.featureService.isEnabled(Feature.ImprovedRemediationStatus) || this.featureService.isEnabled(Feature.ImprovedRemediationStatusSilentMode);
		if (improvedRemediationStatusEnabled && fileInstanceAndProcessSidePanelsEnabled) {
			if (entityType.id === 'process' && entity.uniqueEvidenceId) {
				return 'processEvidence';
			}
			else if (entityType.id === 'fileInstance' && entity.uniqueEvidenceId) {
				return'fileEvidence';
			}
		}
		if (!fileInstanceAndProcessSidePanelsEnabled) {
			if (entityType.id === 'process' || entityType.id === 'fileInstance') {
				return 'file';
			}
		}

		return entityType.id;
	}

	private openReactEntityPanel(angularEntityType: EntityType<any>, reactEntityType: string, entity: any,
								 entityConstructor: DataEntityType<any, any, string>, panelSettings: PanelSettings, contextLog?: string) {
		AngularWrapperPanelService.closeAllPanels(false);

		if (angularEntityType.id === 'aaduser') {
			this.openReactMcasUserPanel(entity, entityConstructor, panelSettings);
			return;
		}

		openEntityPanel(
			{
				entityType: reactEntityType as EntityTypeWithPanelSupport,
				entityId: entity.id,
				partialEntityData: entity,
				contextLog: contextLog,
				isFlyout: true,
				onMainEntityDataChanged: () => {
					AngularWrapperPanelService.closeAllPanels();
					this.onAction.emit({
						entityType: angularEntityType,
						action: { refreshOnResolve: true },
					} as EntityPanelActionEvent<EntityModelBase<string>>);
					this._entityPanels.delete(entityConstructor);
					this.onCloseEntityPanel.emit(entityConstructor);
					this.setCurrentlyDisplayedEntities(entityConstructor);
				},
			},
			() => {
				this._entityPanels.delete(entityConstructor);
				this.onCloseEntityPanel.emit(entityConstructor);
				this.setCurrentlyDisplayedEntities(entityConstructor);
			},
			panelSettingsToNavigationConfig(panelSettings, this.i18n.strings.common_moveUp, this.i18n.strings.common_moveDown, this.i18n.strings.common_close),
		);
	}

	private openReactMcasUserPanel(entity: any, entityConstructor: DataEntityType<any, any, string>, panelSettings: PanelSettings) {
		const { sid, aadUserId, accountName, domainName } = entity;
		openMcasUserPanel(
			{
				sid,
				aadUserId,
				accountName,
				accountDomain: domainName,
			},
			() => {
				this._entityPanels.delete(entityConstructor);
				this.onCloseEntityPanel.emit(entityConstructor);
				this.setCurrentlyDisplayedEntities(entityConstructor);
			},
			panelSettingsToNavigationConfig(panelSettings, this.i18n.strings.common_moveUp, this.i18n.strings.common_moveDown, this.i18n.strings.common_close),
		);
	}

	getCurrentlyDisplayedItems<TEntity extends ModelBase, TId extends string = string>(
		entityConstructor: DataEntityType<TEntity, any, TId>
	): BehaviorSubject<Array<TEntity>> {
		let entityTypeDisplayedItems$: BehaviorSubject<Array<TEntity>> = <BehaviorSubject<Array<TEntity>>>(
			this._currentlyDisplayedEntities.get(entityConstructor)
		);
		if (!entityTypeDisplayedItems$) {
			entityTypeDisplayedItems$ = new BehaviorSubject<Array<TEntity>>([]);
			this._currentlyDisplayedEntities.set(entityConstructor, entityTypeDisplayedItems$);
		}

		return entityTypeDisplayedItems$;
	}

	closeEntityPanel<T extends EntityModelBase<string | number>>(entityConstructor: DataEntityType<T>): void {
		const panel: ComponentRef<EntityPanelComponent> = this._entityPanels.get(entityConstructor);
		if (panel) panel.destroy();
	}

	closeAllEntityPanels() {
		sccHostService.isSCC && AngularWrapperPanelService.closeAllPanels(false);
		this._entityPanels.forEach(panel => panel.destroy());
	}

	private setCurrentlyDisplayedEntities(
		dataEntityType: DataEntityType,
		entities: Array<ModelBase> = []
	): void {
		let entityTypeDisplayedEntities$: BehaviorSubject<
			Array<ModelBase>
		> = this._currentlyDisplayedEntities.get(dataEntityType);
		if (!entityTypeDisplayedEntities$) {
			entityTypeDisplayedEntities$ = new BehaviorSubject<Array<ModelBase>>(entities);
			this._currentlyDisplayedEntities.set(dataEntityType, entityTypeDisplayedEntities$);
		} else entityTypeDisplayedEntities$.next(entities);
	}

	ngOnDestroy(): void {
		this.closeAllEntityPanels();
		this._panelCreateSubscription && this._panelCreateSubscription.unsubscribe();
	}
}

export interface EntityPanelActionEvent<T extends EntityModelBase = EntityModelBase> {
	entityType: EntityType<T>;
	action: ItemActionModel;
}
