import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import {
	ActionBaseEntity,
	ActionHistory,
	AirsEntity,
	InvestigationAction,
	Machine,
	OfficeInvestigationAction,
	RemediationAction,
	RemediationActionEntity,
	RemediationActionType,
	RemediationActionTypeTypes,
} from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { finalize, map, startWith, tap } from 'rxjs/operators';
import { RemediationActionEntityPanelComponent } from '../../remediation/components/remediation-action.entity-panel.component';
import { ActionHistoryConverterService } from '../services/action-history-converter.service';
import { AppContextService } from '@wcd/config';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { ActionFinderService } from '../services/pending-action-finder.service';
import { CommentModel } from '../../../comments/models/comment.model';
import { I18nService } from '@wcd/i18n';
import { sccHostService } from '@wcd/scc-interface';

const loadingSymbol = Symbol();

@Component({
	selector: 'action-history-base-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="allowMoreActions">
			<ng-container *ngIf="(moreActionsWithHash$ | async) as moreActionsWithHash">
				<section
					*ngIf="moreActionsWithHash === loadingSymbol || moreActionsWithHash?.length > 1"
					data-track-component="ActionHistoryEntityPanel"
					class="wcd-padding-vertical wcd-padding-large-horizontal color-box-gray-25"
				>
					<div class="wcd-flex-horizontal">
						<fab-spinner
							*ngIf="moreActionsWithHash === loadingSymbol; else showData"
							[label]="i18nService.strings.actionsHistory_bulkRemediateFile_finding"
							[labelPosition]="'right'"
							[styles]="{ root: { justifyContent: 'end' } }"
						></fab-spinner>
						<ng-template #showData>
							<span>
								<wcd-checkbox
									[label]="
										'actionsHistory_bulkRemediateFile_apply'
											| i18n: { instanceCount: moreActionsWithHash.length - 1 }
									"
									[(ngModel)]="applyToAllActions"
									(ngModelChange)="setApplyToAllActions()"
								></wcd-checkbox>
							</span>
						</ng-template>
					</div>
				</section>
			</ng-container>
		</ng-container>
		<remediation-action-entity-panel
			[partialEntities]="partialEntities"
			[fullEntities]="fullEntities"
			[options]="customOptions"
			[comments]="actionComments"
			[allowEditComments]="allowEditComments"
			[alertId]="alertId"
			[summarizeEntities]="true"
		>
			<ng-container action-post-description>
				<ng-content select="[action-post-description]"></ng-content>
			</ng-container>
		</remediation-action-entity-panel>
		<wcd-collapsible-section
			*ngIf="(machine$ | async) as machine"
			[label]="i18nService.strings.machines_entityDetails_sections_details"
			[sectionId]="'action-history-panel-device-details'"
		>
			<device-details [machine]="machine" [includeMachineName]="true"></device-details>
		</wcd-collapsible-section>
		<div *ngIf="isLoading" class="wcd-flex-center-all wcd-padding-all">
			<fab-spinner></fab-spinner>
		</div>
	`,
})
export class ActionHistoryBaseEntityPanelComponent<T extends ActionBaseEntity> implements OnDestroy {
	partialEntities: Array<AirsEntity>;
	fullEntities: Array<AirsEntity>;
	isLoading: boolean;
	alertId: string;
	loadingSymbol = loadingSymbol;
	moreActionsWithHash$: Observable<Array<T> | typeof loadingSymbol>;
	applyToAllActions: boolean;
	allowMoreActions: boolean;
	actionHistory: T;
	allowEditComments: boolean;
	actionComments: Array<CommentModel>;
	machine$: Observable<Machine>;
	private actionSubscription = new Subscription();
	private _isInit: boolean;

	@Input()
	private moreActionsFilter: (obs$: Observable<Array<T>>) => Observable<Array<T>>;

	@Input()
	private moreActionsEnabled: boolean;

	@Input()
	private options: {};

	@Input()
	private set isInit(val: boolean) {
		this._isInit = val;
		if (this._isInit && this.actionHistory) {
			this.initData();
		}
	}

	@Input()
	set entity(val: T) {
		this.actionHistory = val;
		if (this._isInit && this.actionHistory) {
			this.initData();
		}
	}

	@Output()
	reset = new EventEmitter<void>();

	@Output()
	investigationActionLoad = new EventEmitter<InvestigationAction>();

	@Output()
	requestMetadataRefresh = new EventEmitter<void>();

	@ViewChild(RemediationActionEntityPanelComponent, { static: true })
	private remediationActionEntityPanelComponent: RemediationActionEntityPanelComponent;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private paris: Paris,
		public appContextService: AppContextService,
		private rbacControlService: RbacControlService,
		private actionFinderService: ActionFinderService,
		public i18nService: I18nService
	) {}

	private initData() {
		this.resetData();
		const machine$ =
			this.actionHistory.machine && this.actionHistory.machine.id
				? this.paris.getItemById<Machine, Machine['id'], Machine['id']>(
						Machine,
						this.actionHistory.machine.id
				  )
				: EMPTY;
		this.machine$ = machine$.pipe(startWith(this.actionHistory.machine));
		this.isLoading = true;
		this.changeDetectorRef.detectChanges();

		const stubAction: RemediationAction = ActionHistoryConverterService.getRemediationActionFromActionHistory(
			this.actionHistory
		);
		this.setActionData(stubAction);
		// for MDI/MCAS & MDE manual action - don't call to api to get more details about the action
		const action$ =
			!this.actionHistory.id ||
			!this.actionHistory.doesInvestigationExist ||
			this.actionHistory.isMcasOrMdiAction ||
			this.actionHistory.isMdeManualAction
				? EMPTY
				: this.actionHistory.isOfficeInvestigation
				? this.paris.getItemById(OfficeInvestigationAction, this.actionHistory.id, undefined, {
						investigationId: this.actionHistory.investigationId,
						tenantId: sccHostService.isSCC ? sccHostService.loginUser.tenantId : null,
				  })
				: this.paris.getItemById(InvestigationAction, this.actionHistory.id, undefined, {
						tenantId: sccHostService.loginUser.tenantId,
				  });

		if (this.actionHistory.isMdeManualAction) {
			/*
				Comment for cloud action (MDE manual action) is returned in the actions list api response,
				hence, need to set the comment in order to show it in the panel
			*/
			this.setComments();
		}

		this.actionSubscription = action$
			.pipe(
				finalize(() => {
					this.isLoading = false;
					this.changeDetectorRef.detectChanges();
				}),
				tap((action: InvestigationAction) => {
					this.investigationActionLoad.emit(action);
					if (!(action instanceof OfficeInvestigationAction)) {
						this.partialEntities =
							action.entities && action.entities.length ? action.entities : null;
					} else {
						this.alertId =
							action.relatedAlerts && action.relatedAlerts.length
								? action.relatedAlerts[0]
								: null;

						if (this.actionHistory.investigationDeeplink) {
							const entityLink =
								this.actionHistory.investigationDeeplink +
								'&sourceId=pendingActionList&entityId=';

							this.fullEntities =
								action.entities &&
								action.entities.map(entity =>
									Object.assign(entity, { deepLink: entityLink + entity.id })
								);
						} else {
							this.fullEntities = action.entities;
						}
					}
					this.changeDetectorRef.detectChanges();
				}),
				map((action: InvestigationAction) =>
					this.getRemediationActionFromInvestigationAction(action, stubAction.remediationActionType)
				)
			)
			.subscribe(action => {
				this.isLoading = false;
				this.setActionData(action);
				this.setComments(action);
			});
	}

	private setActionData(action: RemediationAction) {
		Object.assign(this.actionHistory, {
			actionType: action.remediationActionType,
		});
		this.runAfterEntitySet();
		this.requestMetadataRefresh.emit();
		this.remediationActionEntityPanelComponent.setEntity(action);
		this.changeDetectorRef.markForCheck();
	}

	private getRemediationActionFromInvestigationAction(
		investigationAction: InvestigationAction,
		remediationActionType?: RemediationActionType
	): RemediationAction {
		remediationActionType = investigationAction.remediationActionType || remediationActionType;
		const entities: Array<RemediationActionEntity> =
				investigationAction.entities &&
				investigationAction.entities.map((_entity: AirsEntity) => {
					const entityConfig: Partial<Record<keyof RemediationActionEntity, any>> = {
						id: _entity.id,
						entityTypeId: _entity.type && _entity.type.id,
					};
					return new RemediationActionEntity(entityConfig);
				}),
			actionConfig: Partial<Record<keyof RemediationAction, any>> = {
				id: investigationAction.id,
				remediationActionType: remediationActionType,
				investigationId: investigationAction.investigationId,
				isOfficeInvestigation: investigationAction.isOfficeAction,
				machine: investigationAction.machine,
				commentData: investigationAction.commentData,
			};
		if (investigationAction instanceof OfficeInvestigationAction) {
			actionConfig.description = investigationAction.description;
		}

		return ActionHistoryConverterService.getRemediationActionFromConfig(
			entities,
			remediationActionType,
			actionConfig
		);
	}

	private runAfterEntitySet() {
		this.setMoreActionGetter();
	}

	get customOptions() {
		return Object.assign({}, this.options, {
			type: this.actionHistory.actionType,
			allowStatusSplit: true,
			loadInvestigation:
				!this.actionHistory.isOfficeInvestigation && !!this.actionHistory.investigationId,
			ignoreEntityData: !this.actionHistory.doesInvestigationExist, // office data retention is 30 days, hence, we need to avoid calling to OATP APIs to get entity data
		});
	}

	private resetData() {
		this.partialEntities = null;
		this.fullEntities = null;
		this.alertId = null;
		this.allowEditComments = null;
		this.actionComments = [];
		this.machine$ = null;
		this.reset.emit();
		this.actionSubscription && this.actionSubscription.unsubscribe();
	}

	private setMoreActionGetter() {
		if (!this.rbacControlService.hasRequiredMdePermission([RbacMdeAllowedActions.remediationActions])) {
			return;
		}
		this.allowMoreActions = !!(
			this.moreActionsEnabled &&
			this.customOptions.type &&
			this.customOptions.type.type === RemediationActionTypeTypes.file_quarantine
		);
		if (this.allowMoreActions && !this.moreActionsWithHash$) {
			this.moreActionsWithHash$ = this.actionFinderService
				.getMoreActionsForFile(this.actionHistory, true, this.moreActionsFilter)
				.pipe(startWith(loadingSymbol));
		}
	}

	setApplyToAllActions() {
		this.actionFinderService.useExtraActionsForFile(this.actionHistory, this.applyToAllActions);
	}

	ngOnDestroy() {
		this.actionSubscription && this.actionSubscription.unsubscribe();
		this.actionFinderService.invalidateCache();
	}

	private setComments(action?: RemediationAction) {
		this.allowEditComments = !this.actionHistory.isOfficeInvestigation;
		if (this.actionHistory instanceof ActionHistory) {
			this.allowEditComments = this.allowEditComments && !this.actionHistory.isMdeManualAction;
			if (this.actionHistory.comment) {
				this.actionComments = [
					new CommentModel({
						id: undefined,
						message: this.actionHistory.comment,
						user: this.actionHistory.decidedBy,
						timestamp: this.actionHistory.startTime || this.actionHistory.updateTime,
					}),
				];
			} else if (action && action.commentData) {
				this.actionComments = [
					new CommentModel({
						id: undefined,
						message: action.commentData.approverComment,
						user: action.commentData.approvedBy,
						timestamp: action.commentData.actionApprovedTime,
					}),
				];
			}
		}
	}
}
