import { Entity, EntityField } from '@microsoft/paris';
import { PendingActionDecision } from '../remediation/pending_actions/pending-action-decision.entity';
import { ActionBaseEntity } from './action-base.entity';
import { mapActionFromApiV2ToActionHistory } from './action-center-api-v2.converter';
import { compact } from 'lodash-es';

type UndoState = 'undone' | 'undoable' | null;

@Entity({
	singularName: 'Action',
	pluralName: 'Actions',
	endpoint: (_, query) => {
		if (query.where['useActionCenterApiV2']) {
			return 'actioncenterui/history-actions';
		}
		return query.where && query.where['useMtpApi'] ? 'MtpResponseActions' : 'ResponseActions';
	},
	baseUrl: (config, query) => {
		const useActionCenterApiV2 = query && query.where && query.where['useActionCenterApiV2'];

		return useActionCenterApiV2
			? config.data.serviceUrls.automatedIr
			: config.data.serviceUrls.threatIntel;
	},
	allItemsProperty: 'results',
	separateArrayParams: true,
	parseDataQuery: ActionBaseEntity.convertActionCenterQueryToParams,
	parseData: (data, _, query) => {
		if (query && query.where['useActionCenterApiV2']) {
			// convert data from the new action center service format to the model format
			const results = data && data.Results;
			const convertedData =
				results &&
				(Array.isArray(results) ? results : [results]).map(action =>
					mapActionFromApiV2ToActionHistory(action)
				);

			return { count: data.Count, results: compact(convertedData) };
		}

		return data;
	},
})
export class ActionHistory extends ActionBaseEntity {
	@EntityField({ data: 'DecidedBy' })
	decidedBy: string;

	@EntityField({
		data: 'ActionDecision',
		parse: decision => {
			return decision && decision.toLowerCase();
		},
	})
	decision: PendingActionDecision;

	@EntityField({
		data: 'AdditionalFields.actions.undo.state',
	})
	undoState: UndoState;

	@EntityField({
		data: 'AdditionalFields.raw_command',
	})
	rawCommand: string;

	@EntityField({
		data: 'OriginalActionComment',
	})
	comment: string;

	get isUndoable(): boolean {
		return this.undoState === 'undoable';
	}

	get isUndone(): boolean {
		return this.undoState === 'undone';
	}
}
