import { ChangeDetectorRef, EventEmitter, OnDestroy, } from '@angular/core';
import { ActionHistory, InvestigationAction, Machine, OfficeInvestigationAction, RemediationActionEntity, RemediationActionTypeTypes, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { finalize, map, startWith, tap } from 'rxjs/operators';
import { RemediationActionEntityPanelComponent } from '../../remediation/components/remediation-action.entity-panel.component';
import { ActionHistoryConverterService } from '../services/action-history-converter.service';
import { AppContextService } from '@wcd/config';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { EMPTY, Subscription } from 'rxjs';
import { ActionFinderService } from '../services/pending-action-finder.service';
import { CommentModel } from '../../../comments/models/comment.model';
import { I18nService } from '@wcd/i18n';
import { sccHostService } from '@wcd/scc-interface';
var loadingSymbol = Symbol();
var ActionHistoryBaseEntityPanelComponent = /** @class */ (function () {
    function ActionHistoryBaseEntityPanelComponent(changeDetectorRef, paris, appContextService, rbacControlService, actionFinderService, i18nService) {
        this.changeDetectorRef = changeDetectorRef;
        this.paris = paris;
        this.appContextService = appContextService;
        this.rbacControlService = rbacControlService;
        this.actionFinderService = actionFinderService;
        this.i18nService = i18nService;
        this.loadingSymbol = loadingSymbol;
        this.actionSubscription = new Subscription();
        this.reset = new EventEmitter();
        this.investigationActionLoad = new EventEmitter();
        this.requestMetadataRefresh = new EventEmitter();
    }
    Object.defineProperty(ActionHistoryBaseEntityPanelComponent.prototype, "isInit", {
        set: function (val) {
            this._isInit = val;
            if (this._isInit && this.actionHistory) {
                this.initData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionHistoryBaseEntityPanelComponent.prototype, "entity", {
        set: function (val) {
            this.actionHistory = val;
            if (this._isInit && this.actionHistory) {
                this.initData();
            }
        },
        enumerable: true,
        configurable: true
    });
    ActionHistoryBaseEntityPanelComponent.prototype.initData = function () {
        var _this = this;
        this.resetData();
        var machine$ = this.actionHistory.machine && this.actionHistory.machine.id
            ? this.paris.getItemById(Machine, this.actionHistory.machine.id)
            : EMPTY;
        this.machine$ = machine$.pipe(startWith(this.actionHistory.machine));
        this.isLoading = true;
        this.changeDetectorRef.detectChanges();
        var stubAction = ActionHistoryConverterService.getRemediationActionFromActionHistory(this.actionHistory);
        this.setActionData(stubAction);
        // for MDI/MCAS & MDE manual action - don't call to api to get more details about the action
        var action$ = !this.actionHistory.id ||
            !this.actionHistory.doesInvestigationExist ||
            this.actionHistory.isMcasOrMdiAction ||
            this.actionHistory.isMdeManualAction
            ? EMPTY
            : this.actionHistory.isOfficeInvestigation
                ? this.paris.getItemById(OfficeInvestigationAction, this.actionHistory.id, undefined, {
                    investigationId: this.actionHistory.investigationId,
                    tenantId: sccHostService.isSCC ? sccHostService.loginUser.tenantId : null,
                })
                : this.paris.getItemById(InvestigationAction, this.actionHistory.id, undefined, {
                    tenantId: sccHostService.loginUser.tenantId,
                });
        if (this.actionHistory.isMdeManualAction) {
            /*
                Comment for cloud action (MDE manual action) is returned in the actions list api response,
                hence, need to set the comment in order to show it in the panel
            */
            this.setComments();
        }
        this.actionSubscription = action$
            .pipe(finalize(function () {
            _this.isLoading = false;
            _this.changeDetectorRef.detectChanges();
        }), tap(function (action) {
            _this.investigationActionLoad.emit(action);
            if (!(action instanceof OfficeInvestigationAction)) {
                _this.partialEntities =
                    action.entities && action.entities.length ? action.entities : null;
            }
            else {
                _this.alertId =
                    action.relatedAlerts && action.relatedAlerts.length
                        ? action.relatedAlerts[0]
                        : null;
                if (_this.actionHistory.investigationDeeplink) {
                    var entityLink_1 = _this.actionHistory.investigationDeeplink +
                        '&sourceId=pendingActionList&entityId=';
                    _this.fullEntities =
                        action.entities &&
                            action.entities.map(function (entity) {
                                return Object.assign(entity, { deepLink: entityLink_1 + entity.id });
                            });
                }
                else {
                    _this.fullEntities = action.entities;
                }
            }
            _this.changeDetectorRef.detectChanges();
        }), map(function (action) {
            return _this.getRemediationActionFromInvestigationAction(action, stubAction.remediationActionType);
        }))
            .subscribe(function (action) {
            _this.isLoading = false;
            _this.setActionData(action);
            _this.setComments(action);
        });
    };
    ActionHistoryBaseEntityPanelComponent.prototype.setActionData = function (action) {
        Object.assign(this.actionHistory, {
            actionType: action.remediationActionType,
        });
        this.runAfterEntitySet();
        this.requestMetadataRefresh.emit();
        this.remediationActionEntityPanelComponent.setEntity(action);
        this.changeDetectorRef.markForCheck();
    };
    ActionHistoryBaseEntityPanelComponent.prototype.getRemediationActionFromInvestigationAction = function (investigationAction, remediationActionType) {
        remediationActionType = investigationAction.remediationActionType || remediationActionType;
        var entities = investigationAction.entities &&
            investigationAction.entities.map(function (_entity) {
                var entityConfig = {
                    id: _entity.id,
                    entityTypeId: _entity.type && _entity.type.id,
                };
                return new RemediationActionEntity(entityConfig);
            }), actionConfig = {
            id: investigationAction.id,
            remediationActionType: remediationActionType,
            investigationId: investigationAction.investigationId,
            isOfficeInvestigation: investigationAction.isOfficeAction,
            machine: investigationAction.machine,
            commentData: investigationAction.commentData,
        };
        if (investigationAction instanceof OfficeInvestigationAction) {
            actionConfig.description = investigationAction.description;
        }
        return ActionHistoryConverterService.getRemediationActionFromConfig(entities, remediationActionType, actionConfig);
    };
    ActionHistoryBaseEntityPanelComponent.prototype.runAfterEntitySet = function () {
        this.setMoreActionGetter();
    };
    Object.defineProperty(ActionHistoryBaseEntityPanelComponent.prototype, "customOptions", {
        get: function () {
            return Object.assign({}, this.options, {
                type: this.actionHistory.actionType,
                allowStatusSplit: true,
                loadInvestigation: !this.actionHistory.isOfficeInvestigation && !!this.actionHistory.investigationId,
                ignoreEntityData: !this.actionHistory.doesInvestigationExist,
            });
        },
        enumerable: true,
        configurable: true
    });
    ActionHistoryBaseEntityPanelComponent.prototype.resetData = function () {
        this.partialEntities = null;
        this.fullEntities = null;
        this.alertId = null;
        this.allowEditComments = null;
        this.actionComments = [];
        this.machine$ = null;
        this.reset.emit();
        this.actionSubscription && this.actionSubscription.unsubscribe();
    };
    ActionHistoryBaseEntityPanelComponent.prototype.setMoreActionGetter = function () {
        if (!this.rbacControlService.hasRequiredMdePermission([RbacMdeAllowedActions.remediationActions])) {
            return;
        }
        this.allowMoreActions = !!(this.moreActionsEnabled &&
            this.customOptions.type &&
            this.customOptions.type.type === RemediationActionTypeTypes.file_quarantine);
        if (this.allowMoreActions && !this.moreActionsWithHash$) {
            this.moreActionsWithHash$ = this.actionFinderService
                .getMoreActionsForFile(this.actionHistory, true, this.moreActionsFilter)
                .pipe(startWith(loadingSymbol));
        }
    };
    ActionHistoryBaseEntityPanelComponent.prototype.setApplyToAllActions = function () {
        this.actionFinderService.useExtraActionsForFile(this.actionHistory, this.applyToAllActions);
    };
    ActionHistoryBaseEntityPanelComponent.prototype.ngOnDestroy = function () {
        this.actionSubscription && this.actionSubscription.unsubscribe();
        this.actionFinderService.invalidateCache();
    };
    ActionHistoryBaseEntityPanelComponent.prototype.setComments = function (action) {
        this.allowEditComments = !this.actionHistory.isOfficeInvestigation;
        if (this.actionHistory instanceof ActionHistory) {
            this.allowEditComments = this.allowEditComments && !this.actionHistory.isMdeManualAction;
            if (this.actionHistory.comment) {
                this.actionComments = [
                    new CommentModel({
                        id: undefined,
                        message: this.actionHistory.comment,
                        user: this.actionHistory.decidedBy,
                        timestamp: this.actionHistory.startTime || this.actionHistory.updateTime,
                    }),
                ];
            }
            else if (action && action.commentData) {
                this.actionComments = [
                    new CommentModel({
                        id: undefined,
                        message: action.commentData.approverComment,
                        user: action.commentData.approvedBy,
                        timestamp: action.commentData.actionApprovedTime,
                    }),
                ];
            }
        }
    };
    return ActionHistoryBaseEntityPanelComponent;
}());
export { ActionHistoryBaseEntityPanelComponent };
