import { Ip, IpIpStatsRelationship } from '@wcd/domain';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { EntityPrevalenceWidgetBase } from '../../../../global_entities/components/entity-widgets/prevalence/entity-prevalence.widget.base';
import { I18nService } from '@wcd/i18n';
import { TimeRangesService } from '../../../../shared/services/time-ranges.service';
import { FeaturesService } from '@wcd/config';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl:
		'../../../../global_entities/components/entity-widgets/prevalence/entity-prevalence.widget.html',
})
export class IpPrevalenceWidgetComponent extends EntityPrevalenceWidgetBase<Ip> {
	constructor(
		paris: Paris,
		activatedEntity: ActivatedEntity,
		reportsService: ReportsService,
		i18nService: I18nService,
		timeRangeService: TimeRangesService,
		featuresService: FeaturesService
	) {
		super(paris, activatedEntity, reportsService, i18nService, timeRangeService, featuresService);
	}

	id = 'ip-prevalence-widget';

	entityType = Ip;

	entityStatisticsRelationship = IpIpStatsRelationship;

	dataQuery = {};
}
