import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { IpLocation } from '@wcd/domain';
import {Stream} from "../entity/stream.value-object";

@Entity({
	singularName: 'Ip address',
	pluralName: 'Ip addresses',
	endpoint: 'ips',
	allItemsProperty: 'results',
	baseUrl: (config, query) => {
		return query && query.where && query.where['vNextApi'] ? config.data.serviceUrls.entityPagesService : config.data.serviceUrls.threatIntel;
	},
	cache: {
		time: 1000 * 60 * 5,
		max: 10,
	},
})
export class Ip extends EntityModelBase<string> {
	@EntityField({ data: ['IPAddress', 'IpAddress', 'id', 'address'] }) // IpAddress is deprecated. please use "IPAddress" if possible.
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField({ data: ['IPAddress', 'IpAddress', 'address'] }) // IpAddress is deprecated. please use "IPAddress" if possible.
	readonly name: string;

	@EntityField({ data: 'IpPageUrl', required: false })
	readonly externalIpPageUrl?: string;

	@EntityField({
		data: 'location',
		parse: (data) => new IpLocation(data),
	})
	readonly location?: IpLocation;

	@EntityField({ data: 'startTimeUtc' })
	readonly signInTime?: string;

	@EntityField()
	readonly type?: string;

	@EntityField({ data: 'Domain' })
	readonly domain?: string;

	@EntityField({ data: ['Stream', 'stream'] })
	stream?: Stream;

	@EntityField({data: 'eventTime'})
	eventTime?: Date;
}
