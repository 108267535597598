import { ChangeDetectionStrategy, Component, Injector, ChangeDetectorRef } from '@angular/core';
import {
	Ip,
	IpDetails,
	IpIpDetailsRelationship,
	IpIpStatsRelationship,
	IpActiveAlertsSummaryRelationship,
	EntityStatistics,
	AlertsSeveritySummary,
} from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { Paris } from '@microsoft/paris';
import { finalize, tap, map } from 'rxjs/operators';
import { ContentState } from '@wcd/contents-state';
import { Observable, combineLatest, of } from 'rxjs';
import { catchHttpError } from '../../../utils/rxjs/rxjs-custom-operators';
import { TimeRangeValue, TimeRangeId, SpecificTimeRangeValue } from '@wcd/date-time-picker';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';

@Component({
	selector: 'ip-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './ip.entity-details.component.html',
})
export class IpEntityDetailsComponent extends EntityDetailsComponentBase<Ip> {
	get ip(): Ip {
		return this.entity;
	}

	get showIpStats(): boolean {
		// don't show ip stats in entity page - there's a widget dedicated for them
		return this.mode !== EntityDetailsMode.EntityPage;
	}

	private get showIpAlertsCount(): boolean {
		return this.mode === EntityDetailsMode.EntityPage;
	}

	readonly dataAsset = this.i18nService.get('help_externalLoadError_data_asset');

	ipDetails$: Observable<IpExtendedDetails>;
	ipDetailsContentState: ContentState = ContentState.Loading;
	ipStats$: Observable<EntityStatistics>;
	ipStatsContentState: ContentState = ContentState.Loading;

	currentRange: TimeRangeValue;
	timeRanges: ReadonlyArray<TimeRangeValue> = this.timeRangesService.standard;

	constructor(
		injector: Injector,
		private changeDetectorRef: ChangeDetectorRef,
		private readonly paris: Paris,
		private timeRangesService: TimeRangesService,
		private readonly i18nService: I18nService,
		private readonly featuresService: FeaturesService
	) {
		super(injector);
	}

	setEntity(entity: Ip) {
		super.setEntity(entity);

		this.setIpDetails();

		if (this.showIpStats) {
			this.currentRange = this.timeRanges.find(range => range.id === TimeRangeId.month);
			this.onStatRangeChange();
		}

		this.changeDetectorRef.markForCheck();
	}

	setIpDetails() {
		this.ipDetailsContentState = ContentState.Loading;

		const ipDetails$: Observable<IpDetails> = this.paris
			.getRelatedItem<Ip, IpDetails>(IpIpDetailsRelationship, this.entity, {where: {vNextApi: this.featuresService.isEnabled(Feature.K8SMigrationGetIpGetIpStatsKW)}})
			.pipe(catchHttpError(404, null));
		const ipAlerts$: Observable<AlertsSeveritySummary> = this.showIpAlertsCount
			? this.paris
					.getRelatedItem<Ip, AlertsSeveritySummary>(IpActiveAlertsSummaryRelationship, this.entity)
					.pipe(catchHttpError(404, new AlertsSeveritySummary()))
			: of(null);

		this.ipDetails$ = combineLatest(ipDetails$, ipAlerts$).pipe(
			map(([ipDetails, alertsSummary]: [IpDetails, AlertsSeveritySummary]) => ({
				...ipDetails,
				alertsCount: alertsSummary && alertsSummary.alertsCount,
				incidentsCount: alertsSummary && alertsSummary.incidentsCount,
			})),
			tap(
				ipExtendedDetails => (this.ipDetailsContentState = ContentState.Complete),
				() => (this.ipDetailsContentState = ContentState.Error)
			),
			finalize(() => this.changeDetectorRef.markForCheck())
		);
	}

	onStatRangeChange() {
		this.ipStatsContentState = ContentState.Loading;
		this.ipStats$ = this.paris
			.getRelatedItem<Ip, EntityStatistics>(IpIpStatsRelationship, this.entity, {
				where: { lookingBackInDays: (<SpecificTimeRangeValue>this.currentRange).value, vNextApi: this.featuresService.isEnabled(Feature.K8SMigrationGetIpGetIpStatsKW) },
			})
			.pipe(
				tap(
					ipStats =>
						(this.ipStatsContentState = ipStats ? ContentState.Complete : ContentState.Empty),
					() => (this.ipStatsContentState = ContentState.Error)
				),
				finalize(() => this.changeDetectorRef.markForCheck())
			);
	}
}

class IpExtendedDetails extends IpDetails {
	alertsCount: number;
	incidentsCount: number;
}
