var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ComponentFactoryResolver, EventEmitter } from '@angular/core';
import { Paris } from '@microsoft/paris';
import assertNever from 'assert-never';
import { merge, EMPTY } from 'rxjs';
import { filter, map, mergeMap, catchError } from 'rxjs/operators';
import { IsolationTypeValues, Machine, MachineCancelAntivirusScanApiCall, MachineCancelCollectInvestigationPackageApiCall, MachineCancelIsolationApiCall, MachineCancelRestrictAppExecutionApiCall, MachineCollectInvestigationPackageApiCall, MachineIsolateApiCall, MachineMachineRequestLatestRelationship, MachineRemoveAppExecutionRestrictionApiCall, MachineRestrictAppExecutionApiCall, MachineRunAntivirusScanApiCall, MachineUnisolateApiCall, MachineIsolatePublicApiApiCall, MachineReleaseIsolatePublicApiApiCall, RestrictAppExecutionPublicApiApiCall, RemoveRestrictAppExecutionPublicApiApiCall, MachineLogsCollectionApiCall, MachineCancelLogsCollectionApiCall, } from '@wcd/domain';
import { PanelService } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { IsolateModalComponent } from '../components/modal/isolate-modal.component';
import { RunAntivirusScanModalComponent, } from '../components/modal/run-antivirus-scan-modal.component';
import { MachineActionCenterPanelComponent } from '../components/panel/action-center/action-center.component';
import { TroubleshootingModePanelComponent } from '../components/panel/troubleshooting-mode/troubleshooting-mode.component';
import { toPromise } from '../../../utils/rxjs/utils';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { v4 as uuid4 } from 'uuid';
import { ConfirmationService } from '../../../dialogs/confirm/confirm.service';
import { MagellanIsolationStatusService } from './magellan-isolation.service';
var MAX_CONCURRENT_ISOLATION_REQUESTS = 5;
var MachinesActionsService = /** @class */ (function () {
    function MachinesActionsService(paris, i18nService, dialogsService, panelService, componentFactoryResolver, globalEntityTypesService, magellanIsolationStatusProvider, confirmationService) {
        this.paris = paris;
        this.i18nService = i18nService;
        this.dialogsService = dialogsService;
        this.panelService = panelService;
        this.componentFactoryResolver = componentFactoryResolver;
        this.globalEntityTypesService = globalEntityTypesService;
        this.magellanIsolationStatusProvider = magellanIsolationStatusProvider;
        this.confirmationService = confirmationService;
    }
    MachinesActionsService.prototype.collectInvestigationPackage = function (machine) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: 'machines.entityDetails.actions.collectInvestigationPackage.dialog.title',
            titleKeyParams: {
                machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
            },
            textKey: 'machines.entityDetails.actions.collectInvestigationPackage.dialog.content',
            kind: 'create',
            onConfirm: function (_a) {
                var comment = _a.comment;
                return _this.paris.apiCall(MachineCollectInvestigationPackageApiCall, { machine: machine, comment: comment });
            },
        });
    };
    MachinesActionsService.prototype.collectSupportLogs = function (machine) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: 'machines.entityDetails.actions.logsCollection.dialog.title',
            titleKeyParams: {
                machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
            },
            textKey: 'machines.entityDetails.actions.logsCollection.dialog.content',
            kind: 'create',
            onConfirm: function (_a) {
                var comment = _a.comment;
                return _this.paris.apiCall(MachineLogsCollectionApiCall, { machine: machine, comment: comment });
            },
        });
    };
    MachinesActionsService.prototype.openActionCenter = function (theMachine, requestGuid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.panelService
                            .create(MachineActionCenterPanelComponent, null, { machine: theMachine, requestGuid: requestGuid })
                            .toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MachinesActionsService.prototype.openTroubleshootingModePanel = function (theMachine) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.panelService
                            .create(TroubleshootingModePanelComponent, null, { machine: theMachine })
                            .toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MachinesActionsService.prototype.runAntivirusScan = function (machine) {
        var _this = this;
        return this.dialogsService.confirm({
            componentType: RunAntivirusScanModalComponent,
            componentFactoryResolver: this.componentFactoryResolver,
            inputs: {
                machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
            },
            onConfirm: function (_a) {
                var comment = _a.comment, scanType = _a.scanType;
                return _this.paris.apiCall(MachineRunAntivirusScanApiCall, { machine: machine, comment: comment, scanType: scanType });
            },
        });
    };
    MachinesActionsService.prototype.restrictAppExecution = function (machine) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: 'machines.entityDetails.actions.restrictAppExecution.dialog.restrict.title',
            titleKeyParams: {
                machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
            },
            textKey: 'machines.entityDetails.actions.restrictAppExecution.dialog.restrict.content',
            kind: 'create',
            onConfirm: function (_a) {
                var comment = _a.comment;
                return _this.paris.apiCall(MachineRestrictAppExecutionApiCall, { machine: machine, comment: comment });
            },
        });
    };
    MachinesActionsService.prototype.multipleRestrictAppExecution = function (machines) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: 'machines_entityDetails_actions_multipleRestrictAppExecution_dialog_restrict_title',
            titleKeyParams: {
                machineCount: machines.length,
            },
            textKey: 'machines_entityDetails_actions_restrictAppExecution_dialog_restrict_content',
            kind: 'create',
            onConfirm: function (_a) {
                var comment = _a.comment;
                var bulkId = uuid4();
                var responses = machines.map(function (machine) {
                    return _this.paris
                        .apiCall(RestrictAppExecutionPublicApiApiCall, {
                        comment: comment,
                        externalId: bulkId,
                        machineId: machine.id,
                    })
                        .pipe(catchError(function (err) { return EMPTY; }));
                });
                // TODO: This is a temp solution, only for DEMO. The logic of the multi-select should be done in the BE.
                merge.apply(void 0, responses.concat([MAX_CONCURRENT_ISOLATION_REQUESTS])).toPromise();
                _this.showBulkActionsConfirmationDialog(bulkId);
                return Promise.resolve();
            },
        });
    };
    MachinesActionsService.prototype.removeAppRestrictions = function (machine) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: 'machines.entityDetails.actions.restrictAppExecution.dialog.removeRestriction.title',
            titleKeyParams: {
                machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
            },
            textKey: 'machines.entityDetails.actions.restrictAppExecution.dialog.removeRestriction.content',
            kind: 'create',
            onConfirm: function (_a) {
                var comment = _a.comment;
                return _this.paris.apiCall(MachineRemoveAppExecutionRestrictionApiCall, { machine: machine, comment: comment });
            },
        });
    };
    MachinesActionsService.prototype.multipleRemoveAppRestrictions = function (machines) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: 'machines_entityDetails_actions_multipleRestrictAppExecution_dialog_removeRestriction_title',
            titleKeyParams: {
                machineCount: machines && machines.length,
            },
            textKey: 'machines_entityDetails_actions_restrictAppExecution_dialog_removeRestriction_content',
            kind: 'create',
            onConfirm: function (_a) {
                var comment = _a.comment;
                var bulkId = uuid4();
                var responses = machines.map(function (machine) {
                    return _this.paris
                        .apiCall(RemoveRestrictAppExecutionPublicApiApiCall, {
                        comment: comment,
                        externalId: bulkId,
                        machineId: machine.id,
                    })
                        .pipe(catchError(function (err) { return EMPTY; }));
                });
                // TODO: This is a temp solution, only for DEMO. The logic of the multi-select should be done in the BE.
                merge.apply(void 0, responses.concat([MAX_CONCURRENT_ISOLATION_REQUESTS])).toPromise();
                _this.showBulkActionsConfirmationDialog(bulkId);
                return Promise.resolve();
            },
        });
    };
    MachinesActionsService.prototype.isolate = function (machine, isSelectiveIsolationEnabled, callback) {
        var _this = this;
        if (callback === void 0) { callback = function () { return Promise.resolve(undefined); }; }
        return this.dialogsService.confirm({
            componentType: IsolateModalComponent,
            componentFactoryResolver: this.componentFactoryResolver,
            inputs: {
                device: machine,
                machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
                showAllowOutlookAndSkypeCheckbox: isSelectiveIsolationEnabled && machine.isManagedByMdatp,
            },
            onConfirm: function (_a) {
                var comment = _a.comment, allowOutlookAndSkype = _a.allowOutlookAndSkype;
                var isolationType = IsolationTypeValues.Full;
                if (allowOutlookAndSkype) {
                    isolationType = IsolationTypeValues.Selective;
                }
                if (!machine.isManagedByMdatp) {
                    isolationType = IsolationTypeValues.UnManagedDevice;
                }
                return _this.paris
                    .apiCall(MachineIsolateApiCall, {
                    machine: machine,
                    comment: comment,
                    isolationType: isolationType,
                })
                    .toPromise()
                    .then(function (response) { return callback().then(function () { return response; }); });
            },
        });
    };
    MachinesActionsService.prototype.multipleIsolate = function (machines, isSelectiveIsolationEnabled) {
        var _this = this;
        return this.dialogsService.confirm({
            componentType: IsolateModalComponent,
            componentFactoryResolver: this.componentFactoryResolver,
            inputs: {
                showAllowOutlookAndSkypeCheckbox: isSelectiveIsolationEnabled,
                isMultipleSelection: true,
            },
            onConfirm: function (_a) {
                var comment = _a.comment, allowOutlookAndSkype = _a.allowOutlookAndSkype, bulkName = _a.bulkName;
                var bulkId = uuid4();
                var isolationType = allowOutlookAndSkype
                    ? IsolationTypeValues.Selective
                    : IsolationTypeValues.Full;
                var responses = machines.map(function (machine) {
                    return _this.paris
                        .apiCall(MachineIsolatePublicApiApiCall, {
                        comment: comment,
                        title: bulkName,
                        isolationType: isolationType,
                        externalId: bulkId,
                        machineId: machine.id,
                    })
                        .pipe(catchError(function (err) { return EMPTY; }));
                });
                // TODO: This is a temp solution, only for DEMO. The logic of the multi-select should be done in the BE.
                merge.apply(void 0, responses.concat([MAX_CONCURRENT_ISOLATION_REQUESTS])).toPromise();
                _this.showBulkActionsConfirmationDialog(bulkId, bulkName);
            },
        });
    };
    MachinesActionsService.prototype.releaseFromIsolation = function (machine, callback) {
        var _this = this;
        if (callback === void 0) { callback = function () { return Promise.resolve(undefined); }; }
        var confirmedEvent = new EventEmitter();
        return this.showSimpleCommentConfirmDialog({
            titleKey: machine.isManagedByMdatp ? 'machines.entityDetails.actions.isolateMachine.dialog.removeIsolation.title' : 'machines.entityDetails.actions.isolateNotManagedDevice.dialog.removeIsolation.title',
            titleKeyParams: { machineName: machine.name || machine.id },
            textKey: 'machines.entityDetails.actions.isolateMachine.dialog.removeIsolation.content',
            kind: 'create',
            showLoaderAndCloseOnEvent: confirmedEvent,
            onConfirm: function (_a) {
                var comment = _a.comment;
                return _this.paris
                    .queryForItem(MachineMachineRequestLatestRelationship, machine)
                    .pipe(map(function (_a) {
                    var items = _a.items;
                    return items.find(function (item) { return item.type === 'IsolationResponse'; });
                }), filter(function (isolationRequest) { return Boolean(isolationRequest); }), map(function (isolationRequest) {
                    return isolationRequest.isolationType;
                }), mergeMap(function (isolationType) {
                    return _this.paris.apiCall(MachineUnisolateApiCall, { machine: machine, comment: comment, isolationType: isolationType });
                }))
                    .toPromise()
                    .then(function (response) {
                    return callback().then(function () {
                        confirmedEvent.emit();
                        return response;
                    });
                });
            },
        });
    };
    MachinesActionsService.prototype.multipleReleaseFromIsolation = function (machines) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: 'machines_entityDetails_actions_isolateMachine_dialog_multiRemoveIsolation_title',
            titleKeyParams: { machineCount: machines && machines.length },
            textKey: 'machines_entityDetails_actions_multipleIsolateMachine_dialog_removeIsolation_content',
            kind: 'create',
            onConfirm: function (_a) {
                var comment = _a.comment;
                var bulkId = uuid4();
                var responses = machines.map(function (machine) {
                    return _this.paris
                        .apiCall(MachineReleaseIsolatePublicApiApiCall, {
                        comment: comment,
                        externalId: bulkId,
                        machineId: machine.id,
                    })
                        .pipe(catchError(function (err) { return EMPTY; }));
                });
                // TODO: This is a temp solution, only for DEMO. The logic of the multi-select should be done in the BE.
                merge.apply(void 0, responses.concat([MAX_CONCURRENT_ISOLATION_REQUESTS])).toPromise();
                _this.showBulkActionsConfirmationDialog(bulkId);
                return Promise.resolve();
            },
        });
    };
    MachinesActionsService.prototype.cancelCollectInvestigationPackage = function (requestGuid) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: 'machines.actionCenter.actions.cancelCollectInvestigationPackage.dialog.title',
            textKey: 'machines.actionCenter.actions.cancelCollectInvestigationPackage.dialog.content',
            kind: 'cancel',
            onConfirm: function (_a) {
                var comment = _a.comment;
                return _this.paris.apiCall(MachineCancelCollectInvestigationPackageApiCall, { requestGuid: requestGuid, comment: comment });
            },
        });
    };
    MachinesActionsService.prototype.cancelAntivirusScan = function (requestGuid) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: 'machines.actionCenter.actions.cancelAntivirusScan.dialog.title',
            textKey: 'machines.actionCenter.actions.cancelAntivirusScan.dialog.content',
            kind: 'cancel',
            onConfirm: function (_a) {
                var comment = _a.comment;
                return _this.paris.apiCall(MachineCancelAntivirusScanApiCall, { requestGuid: requestGuid, comment: comment });
            },
        });
    };
    MachinesActionsService.prototype.cancelRestrictAppExecution = function (requestGuid) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: 'machines.actionCenter.actions.cancelRestrictAppExecution.dialog.title',
            textKey: 'machines.actionCenter.actions.cancelRestrictAppExecution.dialog.content',
            kind: 'cancel',
            onConfirm: function (_a) {
                var comment = _a.comment;
                return _this.paris.apiCall(MachineCancelRestrictAppExecutionApiCall, { requestGuid: requestGuid, comment: comment });
            },
        });
    };
    MachinesActionsService.prototype.cancelIsolation = function (action, requestGuid) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: action === 'Isolate'
                ? 'machines.actionCenter.actions.cancelIsolation.dialog.title.isolate'
                : action === 'Unisolate'
                    ? 'machines.actionCenter.actions.cancelIsolation.dialog.title.removeIsolation'
                    : assertNever(action),
            textKey: 'machines.actionCenter.actions.cancelIsolation.dialog.content',
            kind: 'cancel',
            onConfirm: function (_a) {
                var comment = _a.comment;
                return _this.paris.apiCall(MachineCancelIsolationApiCall, { requestGuid: requestGuid, comment: comment });
            },
        });
    };
    MachinesActionsService.prototype.cancelLogsCollection = function (requestGuid) {
        var _this = this;
        return this.showSimpleCommentConfirmDialog({
            titleKey: 'machines.actionCenter.actions.cancelLogsCollection.dialog.title',
            textKey: 'machines.actionCenter.actions.cancelLogsCollection.dialog.content',
            kind: 'cancel',
            onConfirm: function (_a) {
                var comment = _a.comment;
                return _this.paris.apiCall(MachineCancelLogsCollectionApiCall, { requestGuid: requestGuid, comment: comment });
            },
        });
    };
    MachinesActionsService.prototype.showBulkActionsConfirmationDialog = function (bulkId, remediationName) {
        /*
            The short bulkId (also called remediation ID) is only for user display.
            This ID will be displayed in the AC as well under 'Remediation ID' column.
         */
        var shortBulkId = bulkId && bulkId.substr(bulkId.length - 6);
        var text = remediationName
            ? this.i18nService.get('machines_entityDetails_actions_multiple_machineAction_actionSubmitted_message', { bulkId: shortBulkId, remediationName: remediationName })
            : this.i18nService.get('machines_entityDetails_actions_multiple_machineAction_actionSubmitted_message_only_bulkId', { bulkId: shortBulkId });
        this.confirmationService.confirm({
            title: this.i18nService.strings
                .machines_entityDetails_actions_multiple_machineAction_actionSubmitted_title,
            text: text,
            showConfirm: false,
            cancelText: this.i18nService.strings.buttons_done,
        });
    };
    MachinesActionsService.prototype.showSimpleCommentConfirmDialog = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var confirmModalResult, actionResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialogsService.confirm({
                            title: this.i18nService.get(options.titleKey, options.titleKeyParams),
                            text: this.i18nService.get(options.textKey),
                            requireMessage: {
                                type: 'textarea',
                                min: 1,
                                max: 1000,
                                placeholder: this.i18nService.get(options.kind === 'create'
                                    ? 'machines.entityDetails.actions.$general.dialog.form.comment.placeholder'
                                    : options.kind === 'cancel'
                                        ? 'machines.actionCenter.actions.$general.dialog.form.comment.placeholder'
                                        : assertNever(options.kind)),
                                property: 'comment',
                            },
                            showLoaderAndCloseOnEvent: options.showLoaderAndCloseOnEvent,
                        })];
                    case 1:
                        confirmModalResult = _a.sent();
                        if (!confirmModalResult.confirmed) {
                            return [2 /*return*/, { confirmed: false }];
                        }
                        if (!options.onConfirm) return [3 /*break*/, 3];
                        return [4 /*yield*/, toPromise(options.onConfirm(confirmModalResult.data))];
                    case 2:
                        actionResult = _a.sent();
                        return [2 /*return*/, {
                                confirmed: true,
                                data: actionResult,
                            }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return MachinesActionsService;
}());
export { MachinesActionsService };
