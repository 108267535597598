import { SuppressionRuleHistoryItem } from './suppression-rule-history-item.entity';

export const suppressionRuleHistoryItemTypes = [
	{
		id: 'Feedback',
		icon: 'comment',
		getMessage: (historyItem: SuppressionRuleHistoryItem) => `"${historyItem.newValue}"`,
	},
	{
		id: 'Disable',
		icon: 'clear',
		getMessage: (historyItem: SuppressionRuleHistoryItem) =>
			`Changed the rule status to disabled: "${historyItem.newValue}"`,
	},
	{
		id: 'Enable',
		icon: 'check',
		getMessage: (historyItem: SuppressionRuleHistoryItem) =>
			`Changed the rule status to enabled: "${historyItem.newValue}"`,
	},
	{
		id: 'Create',
		icon: 'suppressionRule',
		getMessage: (historyItem: SuppressionRuleHistoryItem) =>
			`Created the rule: "${historyItem.newValue}"`,
	},
	{
		id: 'Edit',
		icon: 'comment',
		getMessage: (historyItem: SuppressionRuleHistoryItem) =>
			`Edited the rule: "${historyItem.newValue}"`,
	}
];
