import { MailMessageUACPending } from '@wcd/domain';
import { Lazy } from '../../../utils/lazy';
import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { FabricIconNames } from '@wcd/scc-common';

@Injectable()
export class MailMessageUACPendingEntityTypeService implements EntityTypeService<MailMessageUACPending> {
	private readonly _entityType = new Lazy<Readonly<EntityType<MailMessageUACPending>>>(() => ({
		entity: MailMessageUACPending,
		id: 'mailMessageUACPending',
		icon: FabricIconNames.Mail,
	}));

	get entityType(): Readonly<EntityType<MailMessageUACPending>> {
		return this._entityType.value;
	}
}
