import { Paris } from '@microsoft/paris';
import { SuppressionRulesFields } from '../services/suppression-rules.fields';
import { SuppressionRule, MdeUserRoleActionEnum } from '@wcd/domain';
import { SESSION_NEW_SUPPRESSION, SuppressionRulesService } from '../services/suppression-rules.service';
import { AuthService } from '@wcd/auth';
import { Feature, FeaturesService } from '@wcd/config';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { I18nService } from '@wcd/i18n';
import { MessageBarType } from 'office-ui-fabric-react';
var SuppressionRulesDataviewComponent = /** @class */ (function () {
    function SuppressionRulesDataviewComponent(paris, globalEntityTypesService, authService, suppressionRulesFields, suppressionRulesService, i18nService, performanceSccService, entityPanelsService, featuresService) {
        this.paris = paris;
        this.authService = authService;
        this.suppressionRulesFields = suppressionRulesFields;
        this.suppressionRulesService = suppressionRulesService;
        this.i18nService = i18nService;
        this.performanceSccService = performanceSccService;
        this.entityPanelsService = entityPanelsService;
        this.isScc = sccHostService.isSCC;
        this.MessageBarType = MessageBarType;
        this.entityType = globalEntityTypesService.getEntityType(SuppressionRule);
        this.repository = paris.getRepository(SuppressionRule);
        this.isNewSuppressionEnabled = this.isScc && featuresService.isEnabled(Feature.NewSuppressionRuleCreation);
        this.isNewSuppressionToggleEnabled = localStorage.getItem(SESSION_NEW_SUPPRESSION) !== 'false';
    }
    Object.defineProperty(SuppressionRulesDataviewComponent.prototype, "isUserAllowedActions", {
        get: function () {
            return this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings);
        },
        enumerable: true,
        configurable: true
    });
    SuppressionRulesDataviewComponent.prototype.onTableRenderComplete = function () {
        this.performanceSccService.endNgPageLoadPerfSession('queue');
    };
    SuppressionRulesDataviewComponent.prototype.toggleNewSuppression = function (useNewSuppression) {
        this.entityPanelsService.closeEntityPanel(SuppressionRule);
        sccHostService.log.trackEvent('suppression-switch-experience', { useNewSuppression: useNewSuppression });
        localStorage.setItem(SESSION_NEW_SUPPRESSION, String(useNewSuppression));
    };
    return SuppressionRulesDataviewComponent;
}());
export { SuppressionRulesDataviewComponent };
