var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { Ip } from '../ip.entity';
import { IpDetails } from '../ip-details.value-object';
var ɵ0 = function (config, query) {
    return query && query.where && query.where['vNextApi'] ? config.data.serviceUrls.entityPagesService + '/ips' : config.data.serviceUrls.threatIntel;
}, ɵ1 = function (ip) { return ({
    ip: ip.id,
}); };
var IpIpDetailsRelationship = /** @class */ (function () {
    function IpIpDetailsRelationship() {
    }
    IpIpDetailsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Ip,
            dataEntity: IpDetails,
            fixedData: { lookingBackInDays: 30 },
            allowedTypes: [RelationshipType.OneToOne],
            cache: {
                time: 1000 * 60,
                max: 10,
            },
            baseUrl: ɵ0,
            endpoint: 'GeoLocation',
            allItemsEndpointTrailingSlash: false,
            getRelationshipData: ɵ1,
        })
    ], IpIpDetailsRelationship);
    return IpIpDetailsRelationship;
}());
export { IpIpDetailsRelationship };
export { ɵ0, ɵ1 };
