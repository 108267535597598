var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { UrlThreatType, EmailUrlHelpers } from '@wcd/domain';
var EmailUrlsDetailsPanelComponent = /** @class */ (function (_super) {
    __extends(EmailUrlsDetailsPanelComponent, _super);
    function EmailUrlsDetailsPanelComponent(router, i18nService, appContextService, featuresService) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.appContextService = appContextService;
        _this.featuresService = featuresService;
        _this.CANONICALIZED_URL = 'CanonicalizedUrl';
        _this.NORMALIZED_URL = 'NormalizedUrl';
        var hasUrlPage = appContextService.isMtp || !appContextService.isSCC;
        var hashMethod = _this.featuresService.isEnabled(Feature.EnableNormalizedHashes)
            ? _this.NORMALIZED_URL
            : _this.CANONICALIZED_URL;
        var urlField = Object.assign({
            id: 'url',
            name: _this.i18nService.strings
                .airsEntities_emailMessage_fields_urls_and_threats_url_columnName,
            getTooltip: function (item) { return item.url; },
            className: 'nowrap wcd-text-overflow-medium',
        }, hasUrlPage
            ? {
                onClick: function (item) {
                    // open threatExplorer for the selected url in new tab
                    window.open("/threatexplorer?dltarget=Explorer&dlstorage=Url&viewid=allemail&query-" + hashMethod + "=" + encodeURIComponent(item.url));
                },
                getCssClass: function () { return 'btn-link'; },
            }
            : null);
        var threatsField = {
            id: 'threat',
            name: _this.i18nService.strings
                .airsEntities_emailMessage_fields_urls_and_threats_threat_columnName,
            getTooltip: function (item) { return item.threat; },
            className: 'nowrap wcd-text-overflow-small',
        };
        _this.tableFields = DataTableField.fromList([urlField, threatsField]).map(function (column) {
            column.sort.enabled = false;
            return column;
        });
        return _this;
    }
    EmailUrlsDetailsPanelComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.setItems();
    };
    EmailUrlsDetailsPanelComponent.prototype.setItems = function () {
        var _this = this;
        this.items = this.urls.map(function (url) {
            var threatTypeDisplayName;
            var urlThreatType = _this.getUrlThreatType(url);
            switch (urlThreatType) {
                case UrlThreatType.Spam: {
                    threatTypeDisplayName = _this.i18nService.strings
                        .airsEntities_emailMessage_urls_threat_types_spam;
                    break;
                }
                case UrlThreatType.Phish: {
                    threatTypeDisplayName = _this.i18nService.strings
                        .airsEntities_emailMessage_urls_threat_types_phish;
                    break;
                }
                case UrlThreatType.Malware: {
                    threatTypeDisplayName = _this.i18nService.strings
                        .airsEntities_emailMessage_urls_threat_types_malware;
                    break;
                }
                default: {
                    threatTypeDisplayName = _this.i18nService.strings
                        .airsEntities_emailMessage_urls_threat_types_none;
                }
            }
            return { url: url, threat: threatTypeDisplayName };
        });
    };
    EmailUrlsDetailsPanelComponent.prototype.getUrlThreatType = function (url) {
        var potentiallyHarmfulUrlsArray = Array.from(this.potentiallyHarmfulUrls);
        for (var _i = 0, potentiallyHarmfulUrlsArray_1 = potentiallyHarmfulUrlsArray; _i < potentiallyHarmfulUrlsArray_1.length; _i++) {
            var _a = potentiallyHarmfulUrlsArray_1[_i], partialUrl = _a[0], threatType = _a[1];
            if (EmailUrlHelpers.doesMatch(url, partialUrl)) {
                return threatType;
            }
        }
        return null;
    };
    return EmailUrlsDetailsPanelComponent;
}(PanelContainer));
export { EmailUrlsDetailsPanelComponent };
