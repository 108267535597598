/* tslint:disable:template-accessibility-label-for */
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { DataSet, Paris, Repository } from '@microsoft/paris';
import { clone } from 'lodash-es';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';
import {
	Alert,
	Machine,
	MachineGroup,
	SuppressionRule,
	SuppressionRuleAction,
	SuppressionRuleCondition,
	SuppressionRuleConditionIdType,
	SuppressionRuleConditionIocType,
	SuppressionRuleConditionType,
	SuppressionRuleHistoryItem,
	SuppressionRuleIoc,
	SuppressionRuleScope,
	SuppressionRuleScopeType,
	SuppressionRuleType,
} from '@wcd/domain';
import { AuthService, MtpPermission } from '@wcd/auth';
import { CommentModel } from '../../../comments/models/comment.model';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { ChecklistValue } from '@wcd/forms';
import { I18nService } from '@wcd/i18n';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { MachinesService } from '../../machines/services/machines.service';
import { SuppressionRulesBackendService } from '../services/suppression-rules.backend.service';
import assertNever from 'assert-never';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { SuppressionRulePanelMode, SuppressionRulesService } from '../services/suppression-rules.service';
import { NavItemModel } from '@wcd/shared';
import { RbacControlSettings, RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { ICommandBarStyles } from 'office-ui-fabric-react';
import { ICommandBarItem } from '../../../shared/components/command-bar/models/command-bar-item.models';
import { FabricIconNames } from '@wcd/scc-common';
import { Router } from '@angular/router';
import { AppFlavorConfig } from '@wcd/scc-common';
import { TryItUrls } from '../../../mtp-promotion/enums/try-it-urls';
import { MtpPromotionService } from '../../../mtp-promotion/services/mtp-promotion.service';

const ANY_ALERT_IOC_TYPE: SuppressionRuleIoc = {
	type: SuppressionRuleConditionIocType.Any,
	context: { id: -1, name: 'Any IOC' },
};

export interface AvailableCondition {
	enabled: boolean;
	properties: SuppressionRuleConditionType;
	context: any;
}

const COMMAND_BAR_STYLES: ICommandBarStyles = {
	root: {
		backgroundColor: 'transparent',
	},
};

@Component({
	selector: 'suppression-rule-edit',
	templateUrl: './suppression-rule-edit.component.html',
})
export class SuppressionRuleEditComponent implements OnInit, OnDestroy {
	private _suppressionRule: SuppressionRule;
	@Input() set rule(suppressionRule: SuppressionRule) {
		this._suppressionRule = suppressionRule;
		this.setCommandBarItems(suppressionRule);
	}
	get rule(): SuppressionRule {
		return this._suppressionRule;
	}
	@Input() alert: Alert;
	@Input() refreshOn$: BehaviorSubject<number>;
	@Input() changingRuleStatus$: BehaviorSubject<boolean>;
	@Input() isEditRuleMode: boolean;

	@Output() save: EventEmitter<SuppressionRule> = new EventEmitter<SuppressionRule>();
	@Output() changeStatus: EventEmitter<SuppressionRule> = new EventEmitter<SuppressionRule>();
	@Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('suppressionRuleForm', { static: false }) suppressionRuleForm: NgForm;

	rulesRepo: Repository<SuppressionRule>;
	ruleHistoryItemsRepo: Repository<SuppressionRuleHistoryItem>;
	machineGroupsRepo: Repository<MachineGroup>;
	editedRule: SuppressionRule;
	createRuleMode: boolean;
	placeHolderIoc: string;
	isSaving: boolean = false;
	isSavingComment: boolean = false;
	isError: boolean;
	comment: string = '';
	history: Array<any>;
	availableIocs: Array<SuppressionRuleIoc>;
	isRbacFilteringNecessary: boolean;
	relevantRuleGroups: string = '';
	userExposedMachineGroups: Array<ChecklistValue> = [];
	groupsDropdownPlaceholder: string;
	disableGroupsDropdown: boolean = true;
	isLoadingScopes: boolean = true;
	currentSelectedMachineGroupsIds: Array<number> = [];
	ruleNameEditedFromView: boolean = false;
	commentEditedFromView: boolean = false;
	selectedIoc: SuppressionRuleIoc;
	useSelectedIoc: boolean;
	allAvailableConditions: Array<SuppressionRuleConditionType>;
	currentAvailableConditions: Array<AvailableCondition>;
	selectableActions: Array<SuppressionRuleAction>;
	selectableScopes: Array<SuppressionRuleScope>;
	allSelectableScopes: Array<SuppressionRuleScope>;
	errorLoadingScopeTypes: boolean = false;
	refreshOn: number;
	machineName: string;
	machineLink: Partial<NavItemModel>;
	isSuppressionRuleEditDeleteEnabled: boolean;
	isThreatFamilyNameRelevant: boolean = false;
	machineGroup: any;
	rbacSettingsDisabled: RbacControlSettings;
	rbacSettingsHidden: RbacControlSettings;
	originalRuleNameEdited: boolean;

	overFlowCommandBarStyles: ICommandBarStyles = COMMAND_BAR_STYLES;
	commandBarItems: ICommandBarItem[];
	isDeviceScopeEnabled: boolean;

	readonly suppressionRuleType = SuppressionRuleType;
	readonly urls = TryItUrls;

	setCommandBarItems(rule) {
		this.commandBarItems = [
			{
				name: this.i18nService.get('suppressionRules.edit.open.suppression.page'),
				key: 'SuppressionRuleDetailsGoToRulePage',
				onClick: () => this.onOpenRulePage(),
				iconProps: { iconName: FabricIconNames.PageRight },
			},
			{
				name: this.i18nService.get('suppressionRules.edit.confirmButton'),
				key: 'editRule',
				onClick: () => this.onEdit(),
				iconProps: { iconName: FabricIconNames.EditSolid12 },
			},
			{
				name: this.i18nService.get('suppressionRules.actions.changeStatus', {
					status:
						rule && rule.isEnabled
							? this.i18nService.get('suppressionRules_actions_changeStatus_result_off')
							: this.i18nService.get('suppressionRules_actions_changeStatus_result_on'),
				}),
				key: 'changeStatus',
				onClick: () => this.onChangeRuleStatus(),
				iconProps: {
					iconName: rule && rule.isEnabled ? FabricIconNames.Cancel : FabricIconNames.Accept,
				},
			},
			{
				name: this.i18nService.get('delete'),
				key: 'delete',
				onClick: () => this.onDelete(),
				iconProps: { iconName: FabricIconNames.Delete },
			},
		];
	}

	get noIocUsedOption(): any {
		return ANY_ALERT_IOC_TYPE;
	}

	get isDirty(): boolean {
		return this.suppressionRuleForm.form.dirty;
	}

	get isValid(): boolean {
		if (!this.createRuleMode && !this.isEditRuleMode) {
			return !!this.comment;
		} else {
			return (
				this.editedRule.name &&
				this.comment &&
				this.editedRule.action &&
				this.editedRule.scope &&
				(!this.useSelectedIoc ||
					(this.currentAvailableConditions &&
						this.currentAvailableConditions.some((condition) => condition.enabled)))
			);
		}
	}

	get formValidationMessage(): string {
		const formControls: { [key: string]: AbstractControl } = this.suppressionRuleForm.form.controls,
			firstErrorKey: string = Object.keys(formControls).find(
				(controlId: string) => formControls[controlId].invalid
			),
			firstErrorEl = firstErrorKey ? this.el.nativeElement.querySelector(`#${firstErrorKey}`) : null;
		return `${
			(firstErrorEl && firstErrorEl.getAttribute('data-field-label')) || 'Form'
		} is incomplete or contains errors.`;
	}

	private suppressionRulesUpdateSubscription: Subscription;

	constructor(
		private el: ElementRef,
		public authService: AuthService,
		private paris: Paris,
		private suppressionRulesBackendService: SuppressionRulesBackendService,
		private dialogsService: DialogsService,
		private appInsightsService: AppInsightsService,
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		private flavorService: FlavorService,
		private machinesService: MachinesService,
		private rbacService: RbacService,
		public globalEntityTypesService: GlobalEntityTypesService,
		private suppressionRulesService: SuppressionRulesService,
		private router: Router,
		public mtpPromotionService: MtpPromotionService
	) {
		this.rulesRepo = paris.getRepository(SuppressionRule);
		this.ruleHistoryItemsRepo = paris.getRepository(SuppressionRuleHistoryItem);
		this.allAvailableConditions = <Array<SuppressionRuleConditionType>>(
			paris
				.getRepository(SuppressionRuleConditionType)
				.entity.values.filter(
					(condition) =>
						condition.id !== SuppressionRuleConditionIdType.CommandLine ||
						this.featuresService.isEnabled(Feature.AlertSuppressionByCommandLine)
				)
		);
		this.selectableActions = <Array<SuppressionRuleAction>>(
			paris.getRepository(SuppressionRuleAction).entity.values
		);
		this.machineGroupsRepo = paris.getRepository(MachineGroup);
		this.isDeviceScopeEnabled = this.flavorService.isEnabled(AppFlavorConfig.settings.deviceGroups);
	}

	ngOnInit() {
		if (this.refreshOn$) {
			this.suppressionRulesUpdateSubscription = this.refreshOn$.subscribe((date) => {
				if (this.rule && this.refreshOn && date > this.refreshOn) {
					this.save.emit(this.editedRule);
				}
			});
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		this.isSuppressionRuleEditDeleteEnabled = this.featuresService.isEnabled(
			Feature.SuppressionRuleEditDelete
		);
		if (this.rule && !this.isEditRuleMode) {
			// display rule mode
			this.setRuleFromRule();
			this.createRuleMode = false;
		} else if (this.alert) {
			// create rule mode
			this.setGroupsDropdownPlaceholder();
			this.placeHolderIoc = this.i18nService.get('suppressionRules.display.iocPlaceHolderCreate');
			this.initializeScopes();
			const alertsRepo: Repository<Alert> = this.paris.getRepository(Alert);
			alertsRepo.getItemById(this.alert.id).subscribe((alert: Alert) => {
				this.alert = alert;
				this.setRuleFromAlert();
				this.createRuleMode = true;
			});
		} else if (this.isEditRuleMode) {
			this.setRuleFromRule();
			this.IocPlaceHolder();
			this.setAvailableConditions();
			this.initializeScopes();
			this.setRbacModel();
		} else {
			throw new Error('suppression rule or alert are required in order to show edit rule component.');
		}
	}

	private IocPlaceHolder() {
		this.placeHolderIoc = this.i18nService.get('suppressionRules.display.iocPlaceHolderEdit');
		const currentIoc: SuppressionRuleIoc = {
			type: SuppressionRuleConditionIocType.Ip,
			context: { id: 1, name: this.i18nService.get('suppressionRules.display.iocPlaceHolderEdit') },
		};
		if (this.isEditRuleMode && this.editedRule.conditions.length === 0) {
			this.availableIocs = [ANY_ALERT_IOC_TYPE];
			this.selectedIoc = ANY_ALERT_IOC_TYPE;
		} else {
			this.selectedIoc = currentIoc;
			this.availableIocs = [ANY_ALERT_IOC_TYPE, currentIoc];
		}
	}

	ngOnDestroy() {
		this.suppressionRulesUpdateSubscription && this.suppressionRulesUpdateSubscription.unsubscribe();
	}

	setMachineDetails() {
		this.machineLink = this.globalEntityTypesService.getRouteLinkModel(
			'machine',
			this.editedRule.machine
		);
		this.machineName = this.globalEntityTypesService.getEntityName('machine', this.editedRule.machine);
	}

	setRuleFromRule() {
		this.editedRule = new SuppressionRule(this.rule);
		if (this.editedRule.scope.type === SuppressionRuleScopeType.MachineGroups) {
			this.setRuleGroupsInfo();
		}
		this.useSelectedIoc = !!this.editedRule.ioaDefinitionId;
		this.isThreatFamilyNameRelevant = !!this.rule.threatFamilyName;
		this.setCommentsHistory();
		this.setMachineDetails();
		this.setOriginalRuleNameEdited();
	}

	private setOriginalRuleNameEdited() {
		// Ignores the scope string it is calculated during run time and can be changed according to exiting groups and between the portals
		const templateWithoutScope = this.i18nService.get('suppressionRuleEdit_editedRule_name', {
			actionName: this.i18nService.get(this.editedRule.action.nameI18nKey),
			alertTitle: this.editedRule.alertTitle,
			scopeString: '',
		});
		this.originalRuleNameEdited = !this.rule.name.startsWith(templateWithoutScope);
	}

	setRuleFromAlert(): void {
		this.editedRule = this.rulesRepo.createNewItem();
		this.editedRule.createdBy = this.authService.currentUser.name;
		this.editedRule.alertTitle = this.alert.name;
		this.editedRule.ioaDefinitionId = this.alert.ioaDefinitionId;
		this.editedRule.machine = this.alert.machine;
		this.editedRule.threatFamilyName = this.alert.threatFamilyName;
		this.availableIocs = this.alert.availableIocs.concat([this.noIocUsedOption]);

		this.setSelectableScopes();
		this.setMachineDetails();
	}

	setSelectableScopes(): void {
		if (this.isRbacFilteringNecessary == null) {
			// Machine scope is a scope that should be presented anyway. The other scopes depends on isRbacFilteringNecessary value
			this.selectableScopes = this.allSelectableScopes.filter(
				(scope) => scope.type == SuppressionRuleScopeType.Machine
			);
		} else {
			if (this.isRbacFilteringNecessary) {
				this.selectableScopes = this.allSelectableScopes;
				if (!this.userExposedMachineGroups.length || !this.isDeviceScopeEnabled) {
					// If the user is exposed to 0 machine groups, we remove the option of suppression scope of machine groups
					this.selectableScopes = this.selectableScopes.filter(
						(scope) => scope.type != SuppressionRuleScopeType.MachineGroups
					);
				}
				if (!this.authService.currentUser.isMdeAdmin) {
					// If the user isn't global admin, and RBAC is on, we remove the option of suppression scope of any machine
					this.selectableScopes = this.selectableScopes.filter(
						(scope) => scope.type != SuppressionRuleScopeType.AllOrganization
					);
				}
			} else {
				// If RBAC is off, we remove the option of suppression scope of machine groups
				this.selectableScopes = this.allSelectableScopes.filter(
					(scope) => scope.type != SuppressionRuleScopeType.MachineGroups
				);
			}
		}
		this.machineGroup = this.selectableScopes.filter((scope) => scope.type == 'MachineGroups')[0];
	}

	setRbacModel(): void {
		const rbacSettings = {
			mtpPermissions: [MtpPermission.SecurityConfig_Manage],
			mtpWorkloads: this.alert.mtpWorkloads,
			requireAllPermissions: true,
		};

		this.rbacSettingsHidden = { ...rbacSettings, state: RbacControlState.hidden };
		this.rbacSettingsDisabled = { ...rbacSettings, state: RbacControlState.disabled };
	}

	setAvailableConditions(): void {
		if (this.selectedIoc && this.selectedIoc.type === SuppressionRuleConditionIocType.Any) {
			this.useSelectedIoc = false;
			this.currentAvailableConditions = [];
		} else {
			this.useSelectedIoc = true;
			if (this.isEditRuleMode) {
				this.currentAvailableConditions = this.editedRule.conditions.map((condition) => {
					const currentAvailableCondition: AvailableCondition = {
						enabled: true,
						properties: new SuppressionRuleConditionType({
							pattern: condition.type.pattern,
							patternErrorText: condition.type.patternErrorText,
							allowWildCard: condition.type.allowWildCard,
							propertyName: condition.type.propertyName,
							dataFieldName: condition.type.dataFieldName,
							type: condition.type.type,
							name: condition.type.name,
							id: condition.id,
							allowEdit: true,
						}),
						context: { [condition.type.propertyName]: condition.value },
					};
					return currentAvailableCondition;
				});
			} else {
				this.currentAvailableConditions = this.allAvailableConditions
					.filter(
						(condition) =>
							condition.type === this.selectedIoc.type &&
							!!this.selectedIoc.context[condition.propertyName]
					)
					.map((condition) => {
						return {
							enabled: true,
							properties: condition,
							context: clone(this.selectedIoc.context),
						};
					});
			}
		}
	}

	setRuleNameTemplateChangedScope(action: SuppressionRuleAction, scope: SuppressionRuleScope): void {
		if (scope.type !== SuppressionRuleScopeType.MachineGroups) {
			this.currentSelectedMachineGroupsIds = [];
		} else {
			this.currentSelectedMachineGroupsIds = this.editedRule.deserializedRbacGroupIds;
		}
		this.setRuleNameTemplate(action, scope);
	}

	setRuleNameTemplate(action: SuppressionRuleAction, scope: SuppressionRuleScope): void {
		this.setGroupsDropdownPlaceholder();
		if (!this.originalRuleNameEdited && !this.ruleNameEditedFromView) {
			if (action && scope) {
				let scopeString: string;
				switch (scope.type) {
					case SuppressionRuleScopeType.AllOrganization: {
						scopeString = 'on any device ';
						break;
					}
					case SuppressionRuleScopeType.Machine: {
						scopeString =
							'on device ' +
							(this.editedRule.machine &&
								(this.globalEntityTypesService.getEntityName(
									Machine,
									this.editedRule.machine
								) ||
									this.editedRule.machine.id));
						break;
					}
					case SuppressionRuleScopeType.MachineGroups: {
						const pluralEnding =
							this.currentSelectedMachineGroupsIds &&
							this.currentSelectedMachineGroupsIds.length != 1
								? 's'
								: '';
						scopeString = `on ${this.currentSelectedMachineGroupsIds.length} device group${pluralEnding}`;
						break;
					}
					case SuppressionRuleScopeType.Conditional: {
						scopeString = 'Base on scope conditions';
						break;
					}
					default:
						assertNever(scope.type);
				}
				this.editedRule.name = this.i18nService.get('suppressionRuleEdit_editedRule_name', {
					actionName: this.i18nService.get(action.nameI18nKey),
					alertTitle: this.editedRule.alertTitle,
					scopeString,
				});
			}
		}
		if (this.isRbacFilteringNecessary && scope) {
			if (scope.type == SuppressionRuleScopeType.MachineGroups) {
				this.disableGroupsDropdown = false;
			} else {
				// if user changes his chosen radio button, disable the select group machine option
				this.disableGroupsDropdown = true;
			}
		}
	}

	setRuleNameEditedFromView(): void {
		this.ruleNameEditedFromView = true;
	}

	setCommentEditedFromView(): void {
		this.commentEditedFromView = true;
	}

	setCommentsHistory(): void {
		this.ruleHistoryItemsRepo
			.query({ where: { id: this.rule.id } }, { ignoreFieldsCasing: true })
			.pipe(map((dataSet: DataSet<SuppressionRuleHistoryItem>) => dataSet.items))
			.subscribe((items) => {
				this.history = items.map((ruleHistoryItem: SuppressionRuleHistoryItem) => {
					return new CommentModel({
						id: ruleHistoryItem.id,
						timestamp: ruleHistoryItem.timestamp,
						user: ruleHistoryItem.userName,
						message: ruleHistoryItem.message,
						icon: ruleHistoryItem.type && ruleHistoryItem.type.icon,
					});
				});
			});
	}

	addCommentToRule(comment: string): void {
		this.isSavingComment = true;
		const commentForSave = this.getCommentBackendData(comment);

		this.suppressionRulesBackendService.addCommentToRule(this.rule.id, commentForSave).subscribe(
			() => {
				this.isSavingComment = false;
				this.suppressionRuleForm.form.markAsPristine();
				this.appInsightsService.track({
					type: TrackingEventType.EntitySave,
					id: this.rulesRepo.entity.singularName,
				});
				this.dialogsService.showSuccessSnackbar({
					text: 'Comment saved for suppression rule',
				});

				this.setCommentsHistory();
			},
			(error) => {
				this.isSavingComment = false;
			}
		);
	}

	isSuppressionRuleOnMachineScope(scope: SuppressionRuleScope): boolean {
		return scope.type == SuppressionRuleScopeType.Machine;
	}

	setRuleGroupsInfo(): void {
		this.machineGroupsRepo.allItems$
			.pipe(
				map((allMachineGroups: Array<MachineGroup>) => {
					const relevantGroups = allMachineGroups
						.filter((group) => this.editedRule.deserializedRbacGroupIds.includes(group.id))
						.map((machineGroup: MachineGroup) =>
							machineGroup.isUnassignedMachineGroup
								? this.i18nService.get('machineGroup.unassignedGroup.name')
								: machineGroup.name
						);
					return `-  ${relevantGroups.join('\n- ')}`;
				})
			)
			.subscribe((relevantGroups: string) => {
				this.relevantRuleGroups = relevantGroups;
			});
	}

	setGroupsDropdownPlaceholder() {
		const numSelectedGroups = this.currentSelectedMachineGroupsIds.length;
		this.groupsDropdownPlaceholder =
			numSelectedGroups > 0
				? numSelectedGroups > 1
					? this.i18nService.get('suppressionRules.edit.groupsDropdownPlaceholderWithInfo', {
							numGroups: numSelectedGroups,
					  })
					: this.i18nService.get('suppressionRules.edit.groupsDropdownPlaceholderWithInfo.single')
				: this.i18nService.get('suppressionRules.edit.groupsDropdownPlaceholder');
	}

	saveSuppressionRule(): void {
		this.isSaving = true;
		const ruleForSave: SuppressionRule = this.getRuleBackendData();
		this.paris
			.getRepository(SuppressionRule)
			.save(ruleForSave, null, { comment: this.comment })
			.pipe(finalize(() => (this.isSaving = false)))
			.subscribe(
				(savedSuppressionRule: SuppressionRule) => {
					this.suppressionRuleForm.form.markAsPristine();
					this.appInsightsService.track({
						type: TrackingEventType.EntitySave,
						id: this.rulesRepo.entity.singularName,
					});
					this.dialogsService.showSuccessSnackbar({
						text: 'Suppression rule was created',
					});
					this.save.emit(savedSuppressionRule);
				},
				(error) => {
					this.isError = true;
				}
			);
	}

	onChangeRuleStatus() {
		this.refreshOn = new Date().valueOf();
		this.changeStatus.emit(this.editedRule);
	}

	private getCommentBackendData(comment: string): any {
		return {
			ContextObjId: this.rule.id,
			NewValue: comment,
		};
	}

	private _getMachineGroupCheckListValue(machineGroup: MachineGroup): ChecklistValue {
		return {
			id: machineGroup.id,
			name: machineGroup.isUnassignedMachineGroup
				? this.i18nService.get('machineGroup.unassignedGroup.name')
				: machineGroup.name,
		};
	}

	private getRuleBackendData(): SuppressionRule {
		return new SuppressionRule(
			Object.assign({}, this.editedRule, {
				isEnabled: true,
				conditions: this.useSelectedIoc
					? this.currentAvailableConditions
							.filter((condition) => condition.enabled)
							.map((condition) => {
								const conditionTypeId: string = condition.properties.dataFieldName;

								const suppressionRuleCondition: SuppressionRuleCondition = new SuppressionRuleCondition(
									{
										id: conditionTypeId,
										type: condition.properties,
										value: condition.context[condition.properties.propertyName],
									}
								);

								return suppressionRuleCondition;
							})
					: [],
				rbacGroupIds: this.currentSelectedMachineGroupsIds.length
					? this.currentSelectedMachineGroupsIds.join()
					: null,
				threatFamilyName: this.isThreatFamilyNameRelevant ? this.editedRule.threatFamilyName : null,
			})
		);
	}

	private getScopeTitle(scope: SuppressionRuleScope, machine?: Machine): string {
		switch (scope.type) {
			case SuppressionRuleScopeType.AllOrganization: {
				return this.i18nService.get('suppressionRules.scope.organization');
			}
			case SuppressionRuleScopeType.Machine: {
				return this.i18nService.get('suppressionRules.scope.machine', {
					machineName:
						(machine && this.globalEntityTypesService.getEntityName(Machine, machine)) || '',
				});
			}
			case SuppressionRuleScopeType.MachineGroups: {
				const numOfGroups =
					this.rule && this.rule.deserializedRbacGroupIds.length > 0
						? this.rule.deserializedRbacGroupIds.length
						: null;
				return !numOfGroups || numOfGroups > 1
					? this.i18nService.get(`suppressionRules.scope.machineGroups`, {
							numGroups: numOfGroups,
					  })
					: this.i18nService.get(`suppressionRules.scope.machineGroups.single`);
			}
			case SuppressionRuleScopeType.Conditional: {
				return this.i18nService.get(`suppressionRules.scopeType.conditional`);
			}
			default:
				assertNever(scope.type);
		}
	}

	private initializeScopes() {
		this.allSelectableScopes = (<Array<SuppressionRuleScope>>(
			this.paris
				.getRepository(SuppressionRuleScope)
				.entity.values.filter((scope) => scope.type !== SuppressionRuleScopeType.Conditional)
		)).map((scope) => {
			const title: string = this.isEditRuleMode
				? this.getScopeTitle(scope, this.editedRule.machine)
				: this.getScopeTitle(scope, this.alert.machine);
			return Object.assign({}, scope, { title: title });
		});
		if (this.isEditRuleMode && this.editedRule.machine == null) {
			this.allSelectableScopes = this.allSelectableScopes.filter(
				(scope) => scope.type !== SuppressionRuleScopeType.Machine
			);
		}

		if (this.featuresService.isEnabled(Feature.RbacMachineGroups)) {
			this.machineGroupsRepo.allItems$
				.pipe(
					tap((groups: Array<MachineGroup>) => (this.isRbacFilteringNecessary = groups.length > 0)),
					mergeMap(() =>
						this.isRbacFilteringNecessary
							? this.machinesService.getFullUserExposedMachineGroups()
							: of(null)
					),
					tap((userExposedMachineGroups: Array<MachineGroup>) => {
						if (userExposedMachineGroups) {
							this.userExposedMachineGroups = userExposedMachineGroups.map((machineGroup) =>
								this._getMachineGroupCheckListValue(machineGroup)
							);
						}
					})
				)
				.subscribe(
					() => {
						this.setSelectableScopes();
						this.isLoadingScopes = false;
					},
					(error) => {
						this.errorLoadingScopeTypes = true;
						this.isLoadingScopes = false;
					}
				);
		} else {
			this.isRbacFilteringNecessary = false;
			this.setSelectableScopes();
			this.isLoadingScopes = false;
		}

		if (this.isEditRuleMode) {
			// set default values in edit mode
			this.setRuleNameTemplateChangedScope(this.editedRule.action, this.editedRule.scope);

			this.editedRule.scope = this.selectableScopes.filter(
				(scope) => scope.type === this.editedRule.scope.type
			)[0];
		} else {
		}
	}

	onEdit() {
		this.suppressionRulesService
			.showRulePanel(SuppressionRulePanelMode.edit, this.editedRule.id, null)
			.then((data) => {
				if (data) {
					this.save.emit(this.editedRule);
					this.closePanel.emit();
				}
			})
			.catch((error) => {});
	}

	onDelete() {
		this.suppressionRulesService.deleteRule(this.editedRule).then((data) => {
			if (data && data.isConfirm) {
				this.save.emit(this.editedRule);
				this.closePanel.emit();
			}
		});
	}

	onOpenRulePage() {
		this.router.navigate([`/preferences2/suppressionRule/${this.editedRule.id}`]);
	}
}
