import { Injectable } from '@angular/core';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { Observable } from 'rxjs';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';

@Injectable()
export class SuppressionRulesBackendService {
	constructor(
		private http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService
	) { }

	addCommentToRule(ruleId: number, commentObj: any): Observable<any> {
		return this.http.post(`${this.serviceUrlsService.suppressionRulesService}/SuppressionRules/${ruleId}/Comments`, commentObj);
	}

	changeRuleStatus(enableRule: boolean, statusObj: any): Observable<any> {
		const path: string = enableRule ? 'EnableRule' : 'DisableRule';
		return this.http.post(`${this.serviceUrlsService.suppressionRulesService}/${path}`, statusObj);
	}
}
