
		<div class="wcd-full-height wcd-flex-vertical">
			<div
				class="wcd-flex-none wcd-margin-xxLarge-horizontal wcd-flex-spaceBetween-horizontal
				wcd-flex-center-vertical wcd-margin-large-bottom wcd-padding-none-left-sm-screens tabs-z-index"
			>
				<ng-container *ngIf="(tabs$ | async) as tabs">
					<tabs [tabsData]="tabs" [currentRouteIsActive]="true"></tabs>
				</ng-container>
			</div>
			<div class="wcd-flex-1  ms-background-color-white">
				<router-outlet></router-outlet>
			</div>
		</div>
	