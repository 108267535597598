import { NgModule } from '@angular/core';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { CommentsModule } from '../../comments/comments.module';
import { SuppressionRulesDataviewComponent } from './components/suppression-rules.dataview';
import { SuppressionRulesService } from './services/suppression-rules.service';
import { SuppressionRulePanelComponent } from './components/suppression-rule.panel.component';
import { SuppressionRuleEditComponent } from './components/suppression-rule-edit.component';
import { AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard } from '@wcd/auth';
import { SuppressionRulesBackendService } from './services/suppression-rules.backend.service';
import { DisableRuleConfirmModalComponent } from './components/disable-rule-confirm.modal';
import { SuppressionRuleCommentsPanelComponent } from './components/suppression-rule-comments.panel.component';
import { SuppressionRuleCommentsComponent } from './components/suppression-rule-comments.component';
import { AlertsModule } from '../alerts/alerts.module';
import { SuppressionRule, MdeUserRoleActionEnum } from '@wcd/domain';
import { BreadcrumbsResolver } from '../../breadcrumbs/services/breadcrumbs-resolver.service';
import { PanelsModule } from '@wcd/panels';
import { EntityComponent } from '../../global_entities/components/entity.component';
import { EntityResolver } from '../../global_entities/services/entity.resolver';
import { EntityPageViewMode } from '../../global_entities/models/entity-page-view-mode.enum';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SuppressionRulesEntityTypeService } from './services/suppression-rules.entity-type.service';
import { SuppressionRuleEntityPanelComponent } from './components/suppression-rule.entity-panel.component';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { SuppressionRuleEntityComponent } from './components/suppression-rule.entity.component';
import { HistorySafeRedirectComponent } from '../../shared/components/history-safe-redirect.component';
import { SuppressionRuleAlertsComponent } from './components/suppression-rule.alerts.component';
import { SuppressionRuleConditionsComponent } from './components/suppression-rule.conditions.component';
import { DataTableModule } from '@wcd/datatable';
import { MtpPromotionModule } from '../../mtp-promotion/mtp-promotion.module';
import { MDEConditionsModule } from '../../mde-conditions/mde-conditions.module';
import { FabMessageBarModule } from '@angular-react/fabric';

export const suppressionRulesRoutes: Routes = [
	{
		path: 'suppressionRule/:id',
		component: EntityComponent,
		canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
		resolve: {
			entity: EntityResolver,
			breadcrumbs: BreadcrumbsResolver,
		},
		data: {
			entity: SuppressionRule,
			breadcrumbsConfig: {
				addParentWhenEmpty: true,
				pageMode: EntityPageViewMode.Default,
				itemConfig: {
					icon: 'suppressionRule',
					idFieldName: 'suppressionRuleId',
				},
				parentItemConfig: {
					id: `suppressionRules`,
					labelKey: 'suppressionRules_pageTitle',
					icon: 'suppressionRule',
					url: '/preferences2/alert_suppression',
				},
			},
			pageTitle: 'Suppression Rule',
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData,
			},
		},
		children: [
			{
				path: 'alerts',
				component: SuppressionRuleAlertsComponent,
				data: {
					pageTitle: 'Associated alerts',
					pageTitleKey: 'suppressionRules_tabs_alerts',
				},
			},
			{
				path: 'conditions',
				component: SuppressionRuleConditionsComponent,
				data: {
					pageTitle: 'Rule conditions',
					pageTitleKey: 'suppressionRules_tabs_conditions',
				},
			},
			{
				path: '',
				component: HistorySafeRedirectComponent,
				data: {
					redirectTo: 'alerts'
				},
				pathMatch: 'full',
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forChild(suppressionRulesRoutes),
		SharedModule,
		GlobalEntitiesModule,
		DataViewsModule,
		CommentsModule,
		AlertsModule,
		PanelsModule,
		DataTableModule,
		MtpPromotionModule,
		MDEConditionsModule,
		FabMessageBarModule
	],
	declarations: [
		SuppressionRulesDataviewComponent,
		SuppressionRulePanelComponent,
		SuppressionRuleEditComponent,
		DisableRuleConfirmModalComponent,
		SuppressionRuleCommentsPanelComponent,
		SuppressionRuleCommentsComponent,
		SuppressionRuleEntityPanelComponent,
		SuppressionRuleEntityComponent,
		SuppressionRuleAlertsComponent,
		SuppressionRuleConditionsComponent
	],
	exports: [RouterModule],
	entryComponents: [
		SuppressionRulePanelComponent,
		DisableRuleConfirmModalComponent,
		SuppressionRuleCommentsPanelComponent,
		SuppressionRuleEntityPanelComponent,
		SuppressionRuleEntityComponent
	],
	providers: [SuppressionRulesService, SuppressionRulesBackendService, SuppressionRulesEntityTypeService],
})
export class SuppressionRulesModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(SuppressionRulesEntityTypeService);
	}
}
