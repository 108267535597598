import { Entity, EntityModelBase } from '@microsoft/paris';

@Entity({
	singularName: 'mailMessageUACHistory',
	pluralName: 'mailMessageUACHistory',
	endpoint: 'mailboxes',
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class MailMessageUACHistory extends EntityModelBase {}
