import { MailMessageUACHistory } from '@wcd/domain';
import { Lazy } from '../../../utils/lazy';
import { FabricIconNames } from '@wcd/scc-common';
var MailMessageUACHistoryEntityTypeService = /** @class */ (function () {
    function MailMessageUACHistoryEntityTypeService() {
        this._entityType = new Lazy(function () { return ({
            entity: MailMessageUACHistory,
            id: 'mailMessageUACHistory',
            icon: FabricIconNames.Mail,
        }); });
    }
    Object.defineProperty(MailMessageUACHistoryEntityTypeService.prototype, "entityType", {
        get: function () {
            return this._entityType.value;
        },
        enumerable: true,
        configurable: true
    });
    return MailMessageUACHistoryEntityTypeService;
}());
export { MailMessageUACHistoryEntityTypeService };
