var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector, ChangeDetectorRef } from '@angular/core';
import { IpDetails, IpIpDetailsRelationship, IpIpStatsRelationship, IpActiveAlertsSummaryRelationship, AlertsSeveritySummary, } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { Paris } from '@microsoft/paris';
import { finalize, tap, map } from 'rxjs/operators';
import { ContentState } from '@wcd/contents-state';
import { combineLatest, of } from 'rxjs';
import { catchHttpError } from '../../../utils/rxjs/rxjs-custom-operators';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';
var IpEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(IpEntityDetailsComponent, _super);
    function IpEntityDetailsComponent(injector, changeDetectorRef, paris, timeRangesService, i18nService, featuresService) {
        var _this = _super.call(this, injector) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.paris = paris;
        _this.timeRangesService = timeRangesService;
        _this.i18nService = i18nService;
        _this.featuresService = featuresService;
        _this.dataAsset = _this.i18nService.get('help_externalLoadError_data_asset');
        _this.ipDetailsContentState = ContentState.Loading;
        _this.ipStatsContentState = ContentState.Loading;
        _this.timeRanges = _this.timeRangesService.standard;
        return _this;
    }
    Object.defineProperty(IpEntityDetailsComponent.prototype, "ip", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IpEntityDetailsComponent.prototype, "showIpStats", {
        get: function () {
            // don't show ip stats in entity page - there's a widget dedicated for them
            return this.mode !== EntityDetailsMode.EntityPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IpEntityDetailsComponent.prototype, "showIpAlertsCount", {
        get: function () {
            return this.mode === EntityDetailsMode.EntityPage;
        },
        enumerable: true,
        configurable: true
    });
    IpEntityDetailsComponent.prototype.setEntity = function (entity) {
        _super.prototype.setEntity.call(this, entity);
        this.setIpDetails();
        if (this.showIpStats) {
            this.currentRange = this.timeRanges.find(function (range) { return range.id === "month" /* month */; });
            this.onStatRangeChange();
        }
        this.changeDetectorRef.markForCheck();
    };
    IpEntityDetailsComponent.prototype.setIpDetails = function () {
        var _this = this;
        this.ipDetailsContentState = ContentState.Loading;
        var ipDetails$ = this.paris
            .getRelatedItem(IpIpDetailsRelationship, this.entity, { where: { vNextApi: this.featuresService.isEnabled(Feature.K8SMigrationGetIpGetIpStatsKW) } })
            .pipe(catchHttpError(404, null));
        var ipAlerts$ = this.showIpAlertsCount
            ? this.paris
                .getRelatedItem(IpActiveAlertsSummaryRelationship, this.entity)
                .pipe(catchHttpError(404, new AlertsSeveritySummary()))
            : of(null);
        this.ipDetails$ = combineLatest(ipDetails$, ipAlerts$).pipe(map(function (_a) {
            var ipDetails = _a[0], alertsSummary = _a[1];
            return (__assign({}, ipDetails, { alertsCount: alertsSummary && alertsSummary.alertsCount, incidentsCount: alertsSummary && alertsSummary.incidentsCount }));
        }), tap(function (ipExtendedDetails) { return (_this.ipDetailsContentState = ContentState.Complete); }, function () { return (_this.ipDetailsContentState = ContentState.Error); }), finalize(function () { return _this.changeDetectorRef.markForCheck(); }));
    };
    IpEntityDetailsComponent.prototype.onStatRangeChange = function () {
        var _this = this;
        this.ipStatsContentState = ContentState.Loading;
        this.ipStats$ = this.paris
            .getRelatedItem(IpIpStatsRelationship, this.entity, {
            where: { lookingBackInDays: this.currentRange.value, vNextApi: this.featuresService.isEnabled(Feature.K8SMigrationGetIpGetIpStatsKW) },
        })
            .pipe(tap(function (ipStats) {
            return (_this.ipStatsContentState = ipStats ? ContentState.Complete : ContentState.Empty);
        }, function () { return (_this.ipStatsContentState = ContentState.Error); }), finalize(function () { return _this.changeDetectorRef.markForCheck(); }));
    };
    return IpEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { IpEntityDetailsComponent };
var IpExtendedDetails = /** @class */ (function (_super) {
    __extends(IpExtendedDetails, _super);
    function IpExtendedDetails() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return IpExtendedDetails;
}(IpDetails));
