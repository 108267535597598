import { Injectable, OnDestroy } from '@angular/core';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { AuthService } from '@wcd/auth';
import { Feature, FeaturesService, FlavorService, PollingService } from '@wcd/config';
import { TimeRangeId } from '@wcd/date-time-picker';
import {
	CustomTiIndicator,
	CustomTiIndicatorsType,
	CustomTiIndicatorsTypes,
	CustomTiIndicatorTypeExclusionsRelationship,
	File,
	FileActionType,
	FileFileProtectionInfoRelationship,
	FileFileStatsRelationship,
	FileProtectionInfo,
	FileSearchCriterion,
	FileStats,
	SampleDisabledReason,
	MdeUserRoleActionEnum,
	SampleActionStateResponse,
	ServiceSourceType,
} from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { PanelType } from '@wcd/panels';
import { Lazy } from '@wcd/utils';
import { compact } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { EntityCustomTiActionsConfigService } from '../../../@entities/common/services/entity.custom-ti-actionconfig.service';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { AskThreatExpertService } from '../../../feedback/services/ask-threat-expert.service';
import { FileEntityDetailsComponent } from '../../../global_entities/components/entity-details/file.entity-details.component';
import { EntityMinimizedDetailsComponentBase } from '../../../global_entities/components/entity-minimized/entity-minimized-details.component.base';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityDataViewOptions, EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { TagsService } from '../../../tags/services/tags.service';
import { RegExpService } from '@wcd/shared';
import { FileActionCenterPanelComponent } from '../components/file.action-center/file.action-center.panel.component';
import { FileCollectSamplePanelComponent } from '../components/file.collect-sample/file.collect-sample.panel.component';
import { FileDownloadSamplePanelComponent } from '../components/file.download-sample/file.download-sample.panel.component';
import { FileEntityPanelComponent } from '../components/file.entity-panel/file.entity-panel.component';
import { FileEntityComponent } from '../components/file.entity.component';
import { FilesBackendService } from '../services/files.backend.service';
import { FileActionFeatureStatus, FileActionsService } from './file.actions.service';
import { FilesFieldsService } from './files.fields.service';
import { FilesService } from './files.service';
import { AppContextService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
import { sccHostService } from '@wcd/scc-interface';

export interface FilesDataViewFixedOptions {
	lookingBackIndays: string;
}

export interface FilesDataViewSearchFixedOptions extends FilesDataViewFixedOptions {
	searchTerm: string;
	searchBy: FileSearchCriterion;
	pageSize: number;
}

const pollingIntervalMilliseconds = 5 * 60 * 1000; // 5 minutes

const actionsOrderWeights = {
	quarantine: 10,
	editIndicator: 20,
	collectSample: 30,
	downloadSample: 40,
	consultThreatExpert: 50,
	actionCenter: 60,
};

enum ActionRefreshReason {
	OnStart,
	OnPollingInterval,
	OnQuarantine,
	OnQuarantineStatistics,
	OnQuarantinePermissionsReceived,
	OnIndicatorAdded,
	OnStartedCollecting,
	OnCollectSampleStatistics,
	OnDownloadSampleStatistics,
}

@Injectable()
export class FileEntityTypeService implements EntityTypeService<File>, OnDestroy {
	private _customIndicatorsRepo: RelationshipRepository<CustomTiIndicatorsType, CustomTiIndicator>;
	private _pollSub: Subscription;
	private _quarantineSub: Subscription;
	private _sampleStateSub: Subscription;

	private readonly timeRanges = new Lazy(() =>
		this.timeRangesService.pick([
			TimeRangeId.day,
			TimeRangeId['3days'],
			TimeRangeId.week,
			TimeRangeId.month,
			TimeRangeId['6months'],
		])
	);

	private _onActionStatusRefresh$: BehaviorSubject<ActionRefreshReason> = new BehaviorSubject<
		ActionRefreshReason
	>(ActionRefreshReason.OnStart);

	private _quarantineAction: ItemActionModelConfig;
	private _quarantineStatus: FileActionFeatureStatus;
	private _quarantinePermissions: FileActionFeatureStatus;

	private _downloadSampleAction: ItemActionModelConfig = {
		id: 'downloadSample',
		name: this.i18nService.get('file.sample.download.title'),
		icon: FabricIconNames.Download,
		refreshOnResolve: false,
		method: async ([file]: [File]) => {
			this.trackFileEvent('Improved file page - Download Sample Action');
			this._sampleStateSub && this._sampleStateSub.unsubscribe();
			this._onActionStatusRefresh$.next(ActionRefreshReason.OnDownloadSampleStatistics);
			const topFilenameObservable$ = (file.name ? of(file.name) : this.getTopFilename(file)).pipe(
				map(filename => filename || file.sha1)
			);

			const sha = file.sha1 || file.sha256;

			this._sampleStateSub = combineLatest(
				this.fileBackendService.getSampleState(sha, true),
				topFilenameObservable$
			).subscribe(
				([sampleState, filename]: [SampleActionStateResponse, string]) => {
					if (sampleState.isEnabled) {
						this.dialogsService
							.showPanel(
								FileDownloadSamplePanelComponent,
								{
									headerText: this.i18nService.get('file.sample.download.header'),
									type: PanelType.large,
								},
								{
									file: file,
								}
							)
							.subscribe(
								panel => {
									panel.instance.onDownloadSample.subscribe(
										params => {
											this.fileBackendService
												.downloadFileNew(
													sha,
													file.name ? file.name : filename,
													params.downloadReason,
													params.password
												)
												.subscribe(error =>
													this._onActionStatusRefresh$.next(
														ActionRefreshReason.OnPollingInterval
													)
												);
										},
										error =>
											this._onActionStatusRefresh$.next(
												ActionRefreshReason.OnPollingInterval
											)
									);

									panel.instance.onDestroyPanel.subscribe(confirmed => {
										if (!confirmed) {
											this._onActionStatusRefresh$.next(
												ActionRefreshReason.OnPollingInterval
											);
										}
									});
								},
								error =>
									this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval)
							);
					} else {
						this.dialogsService.showSnackbar({
							text: this.i18nService.get('file.sample.collect.fileWasNotObserved'),
							icon: 'warningSolid',
							iconClassName: 'color-text-warning-dark',
							className: 'color-box-warning-light',
						});

						this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
					}
				},
				error => this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval)
			);
		},
	};

	private _collectSampleAction: ItemActionModelConfig = {
		id: 'collectSample',
		name: this.i18nService.get('file.sample.collect.title'),
		icon: FabricIconNames.SearchAndApps,
		refreshOnResolve: false,
		method: async ([file]: [File]) => {
			this.trackFileEvent('Improved file page - Collect Sample Action');

			this._sampleStateSub && this._sampleStateSub.unsubscribe();
			this._onActionStatusRefresh$.next(ActionRefreshReason.OnCollectSampleStatistics);

			const sha = file.sha1 || file.sha256;

			this._sampleStateSub = this.fileBackendService
				.getSampleState(sha, true)
				.subscribe(sampleState => {
					if (sampleState.isEnabled) {
						this.dialogsService
							.showPanel(
								FileCollectSamplePanelComponent,
								{
									headerText: this.i18nService.get('file.sample.collect.header'),
									type: PanelType.large,
								},
								{
									file: file,
								}
							)
							.subscribe(
								panel => {
									panel.instance.onCollectSample.subscribe(() => {
										this.fileBackendService
											.collectSample(sha, panel.instance.sampleCollectionReason)
											.subscribe(error =>
												this._onActionStatusRefresh$.next(
													ActionRefreshReason.OnPollingInterval
												)
											);

										this._onActionStatusRefresh$.next(
											ActionRefreshReason.OnStartedCollecting
										);
									});

									panel.instance.onDestroyPanel.subscribe(confirmed => {
										if (!confirmed) {
											this._onActionStatusRefresh$.next(
												ActionRefreshReason.OnPollingInterval
											);
										}
									});
								},
								error => {
									this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
								}
							);
					} else {
						this.dialogsService.showSnackbar({
							text: this.i18nService.get('file.sample.collect.fileWasNotObserved'),
							icon: 'warningSolid',
							iconClassName: 'color-text-warning-dark',
							className: 'color-box-warning-light',
						});

						this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
					}
				});
		},
	};

	ngOnDestroy(): void {
		this._onActionStatusRefresh$ && this._onActionStatusRefresh$.unsubscribe();
		this._pollSub && this._pollSub.unsubscribe();
		this._quarantineSub && this._quarantineSub.unsubscribe();
		this._sampleStateSub && this._sampleStateSub.unsubscribe();
	}

	readonly entityType: Readonly<EntityType<File>> = {
		entity: File,
		id: 'file',
		getItem: (entity: File, loadedEntity$: Observable<File>) =>
			loadedEntity$.pipe(
				map(
					(loadedEntity: File) =>
						new File({
							...loadedEntity,
							// take file name and type from the original entity, because the loaded entity represents a general file, which doesn't have a specific name
							fileName: entity.fileName,
							appType: entity.appType,
						})
				)
			),
		getIcon: () => FabricIconNames.Page,
		getImage: (entities: ReadonlyArray<File>) => {
			const folder = '/assets/images/icons/entities/';

			let fileName = 'file';

			const firstEntityType = entities[0].appType;
			if (firstEntityType) {
				const allSameType = entities.every(entity => entity.appType === firstEntityType);

				if (allSameType) {
					fileName += `_${firstEntityType.id}`;
				}
			}

			return `${folder}${fileName}.svg`;
		},
		icon: FabricIconNames.Page,
		entityContentsComponentType: FileEntityComponent,
		entityDetailsComponentType: FileEntityDetailsComponent,
		entityMinimizedComponentType: EntityMinimizedDetailsComponentBase,
		singleEntityPanelComponentType: FileEntityPanelComponent,
		getEntityName: file => file.fileName,
		entityPluralNameKey: 'file_entityType_pluralName',
		entitySingularNameKey: 'file_entityType_singularName',
		getNavigationModel: (file: File, serviceSource?: ServiceSourceType) =>
			(file.externalFilePageUrl || file.id) && {
				externalLink: file.externalFilePageUrl,
				routerLink: !file.externalFilePageUrl
					? this.featuresService.isEnabled(Feature.PortedSccPages) ||
						!serviceSource ||
						serviceSource === ServiceSourceType.Wdatp
						? [this.filesService.getFileLink(file.id)]
						: null
					: null,
				queryParams: file.fileName && this.filesService.getFileLinkParams(file.fileName),
			},
		getEntitiesLink: (files: Array<File>, options) => {
			if (this.flavorService.isEnabled(AppFlavorConfig.routes.files) && files && files.length === 1)
				if (files[0].externalFilePageUrl) {
					return files[0].externalFilePageUrl;
				} else if (files[0].id) {
					let fileLink = this.filesService.getFileLink(files[0].id, null, files[0].fileName);
					if (options && fileLink) {
						fileLink = fileLink.indexOf('/overview') > -1 ? fileLink.replace('/overview', `/${options.tab}`)
						: `${fileLink}/${options.tab}`;
					}
					return fileLink;
				} else {
					return null;
				}
		},
		getUseExternalRouting: (files: Array<File>) =>
			files.length === 1 && Boolean(files[0].externalFilePageUrl),
		getTags: (entities: Array<File>) =>
			this.featuresService.isEnabled(Feature.FileSensitivity) && entities.length === 1
				? this.paris
					.getRelatedItem<File, FileProtectionInfo>(
						FileFileProtectionInfoRelationship,
						entities[0]
					)
					.pipe(
						filter(protectionInfo => protectionInfo.sensitivity != null),
						map(protectionInfo => [
							this.tagsService.getDataSensitivityTag(
								protectionInfo.sensitivity,
								this.i18nService.get('files.sensitivity.tag.tooltip'),
								false
							),
						])
					)
				: of([]),
		dataViewOptions: <
			EntityDataViewOptions<File, FilesDataViewFixedOptions | FilesDataViewSearchFixedOptions>
			>{
				fields: FilesFieldsService,
				dateRangeOptions: {
					defaultTimeRangeId: 'month',
					supportedRanges: this.timeRanges.value,
				},
				fixedOptions: (currentRange, searchTerm) => {
					const baseOptions: FilesDataViewFixedOptions = {
						lookingBackIndays: currentRange.value.toString(),
					};
					return searchTerm
						? {
							...baseOptions,
							searchTerm: searchTerm,
							searchBy: FileSearchCriterion.TopFileNames,
							pageSize: 100,
						}
						: baseOptions;
				},
				defaultSortFieldId: 'matchType',
				exportOptions: {
					showModalOnExport: false,
					exportResults: (options, fixedOptions, searchTerm) => {
						return this.filesService.downloadCsv({
							searchTerm: searchTerm,
							lookingBackInDays: fixedOptions.lookingBackIndays,
						});
					},
				},
				dataViewConfig: {
					allowFilters: true,
					allowPaging: false,
					getFiltersData: fixedOptions => this.filesService.getFilesFilters(),
				},
			},
		searchOptions: {
			displayName: this.i18nService.strings.entity_type_display_name_file,
			resolveTypeFromSearchTerm: (searchTerm: string) => {
				return (
					RegExpService.md5.test(searchTerm) ||
					RegExpService.sha1.test(searchTerm) ||
					RegExpService.sha256.test(searchTerm) ||
					searchTerm.includes('.exe') ||
					searchTerm.includes('.bat') ||
					searchTerm.includes('.dll')
				);
			},
			getSearchParams: (searchTerm: string) => {
				if (
					RegExpService.md5.test(searchTerm) ||
					RegExpService.sha1.test(searchTerm) ||
					RegExpService.sha256.test(searchTerm)
				) {
					return { url: this.filesService.getFileLink(searchTerm) };
				} else {
					const resultsPageName =
						this.appContextService.isSCC ||
							this.featuresService.isEnabled(Feature.UpgradeSearchFiles)
							? 'searchResults'
							: 'search';
					return { url: `/${resultsPageName}/files/${searchTerm}` };
				}
			},
			searchDropdownOrder: 0,
			requiredAllowedActions: MdeUserRoleActionEnum.viewData,
			flavorConfig: AppFlavorConfig.search.commonEntities,
		},
		getItemParams: (file: File, options: any) => {
			const searchBy = this.filesService.getFileSearchCriterion(options && options.routeId);
			return { searchBy, newFilesApi: this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW) };
		},
		getActions: (files: ReadonlyArray<File>, options, entityViewType) => {
			if (files.length !== 1 || !files[0].id) {
				return [];
			}

			const [file] = files;
			this._pollSub && this._pollSub.unsubscribe();
			this._pollSub = this.pollingService
				.poll(pollingIntervalMilliseconds, pollingIntervalMilliseconds)
				.pipe(
					tap(() => {
						this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
					})
				)
				.subscribe();

			return combineLatest(this._onActionStatusRefresh$).pipe(
				switchMap(([refreshReason]) => this.getAllActions(file, refreshReason, entityViewType))
			);
		},
		entityPageViewMode: EntityPageViewMode.Asset,
		hideTagsInEntityComponent: true,
	};

	getExistentCustomIndicators(file, indicatorType) {
		return this.entityCustomTiActionsConfigService.getCustomIndicators([file.sha1, file.sha256, file.md5],
			indicatorType);
	}

	private getTopFilename(file: File): Observable<string> {
		return this.paris
			.getRelatedItem(
				FileFileStatsRelationship,
				file,
				{ where: { filesPrefix: this.featuresService.isEnabled('K8SMigration-EPSFilePrevalence-kw') } })
			.pipe(map((fileStats: FileStats) => fileStats.topFileNames.length > 0 && fileStats.topFileNames[0]));
	}

	constructor(
		private readonly filesService: FilesService,
		private readonly timeRangesService: TimeRangesService,
		private readonly paris: Paris,
		private readonly tagsService: TagsService,
		private readonly featuresService: FeaturesService,
		private readonly i18nService: I18nService,
		private readonly dialogsService: DialogsService,
		private readonly authService: AuthService,
		private readonly fileActionsService: FileActionsService,
		private readonly fileBackendService: FilesBackendService,
		private readonly entityCustomTiActionsConfigService: EntityCustomTiActionsConfigService,
		private readonly askThreadExpertService: AskThreatExpertService,
		private readonly pollingService: PollingService,
		private readonly appContextService: AppContextService,
		private readonly flavorService: FlavorService,
	) {
		this._customIndicatorsRepo = this.paris.getRelationshipRepository(
			CustomTiIndicatorTypeExclusionsRelationship
		);

		this._customIndicatorsRepo.sourceItem = this.paris.getValue(
			CustomTiIndicatorsType,
			CustomTiIndicatorsTypes.Files
		);
	}

	private trackFileEvent(event) {
		sccHostService.log.trackEvent(event);
	}

	private getAllActions(file: File, refreshReason: ActionRefreshReason, entityViewType: EntityViewType) {
		return combineLatest(
			entityViewType === EntityViewType.EntityPage
				? this.createQuarantineActionConfig(file, refreshReason)
				: of(null),
			this.entityCustomTiActionsConfigService.getCommandBarActions<File>(
				file.sha1,
				CustomTiIndicatorsTypes.Files,
				file => file.sha256 || file.sha1,
				[file.sha1, file.sha256, file.md5],
				'filePage'
			),
			this.createSampleActionConfig(file, refreshReason),
			this.createAskThreatExpertConfig(),
			this.createActionCenterActionConfig()
		).pipe(
			map(actions => {
				const getWeight = id => actionsOrderWeights[id] || 999;
				actions.filter(Boolean).sort((a, b) => getWeight(a.id) - getWeight(b.id));
				return compact(actions).map(actionConfig => new ItemActionModel(actionConfig));
			})
		);
	}

	private createAskThreatExpertConfig() {
		return of(
			this.askThreadExpertService.shouldShowThreatExpertPanel()
				? this.askThreadExpertService.getAskTheExpertCommandConfig('fileEntityCommandBar')
				: null
		);
	}

	private createSampleActionConfig(
		file: File,
		refreshReason: ActionRefreshReason
	): Observable<ItemActionModelConfig<void>> {
		// Shortcut for collecting state as it doesn't require communication with the server side
		if (refreshReason === ActionRefreshReason.OnStartedCollecting) {
			return of({
				...this._collectSampleAction,
				disabled: true,
				tooltip: this.i18nService.get('file.sample.collect.ongoingCollection'),
			});
		}

		if (
			refreshReason === ActionRefreshReason.OnCollectSampleStatistics ||
			refreshReason === ActionRefreshReason.OnDownloadSampleStatistics
		) {
			return of({
				...(refreshReason === ActionRefreshReason.OnCollectSampleStatistics
					? this._collectSampleAction
					: this._downloadSampleAction),
				disabled: true,
				tooltip: this.i18nService.get('file.quarantine.analyzingPermissions'),
			});
		}

		return this.fileBackendService.getSampleState(file.sha1 || file.sha256, false).pipe(
			map(sampleState => {
				const disabled: boolean = !sampleState.isEnabled;
				let tooltip: string;
				let buttonAction: ItemActionModelConfig;

				if (sampleState.actionType === FileActionType.Collect) {
					buttonAction = this._collectSampleAction;

					if (sampleState.isEnabled) {
						tooltip = this.i18nService.get('file.sample.collect.tooltip');
					} else {
						switch (sampleState.sampleDisabledReason) {
							case SampleDisabledReason.CollectionInProgress:
								tooltip = this.i18nService.get('file.sample.collect.ongoingCollection');
								break;
							case SampleDisabledReason.UserRbacUnauthorized:
								tooltip = this.i18nService.get('file.sample.collect.noPermissions');
							default:
								break;
						}
					}
				} else {
					buttonAction = this._downloadSampleAction;

					if (sampleState.isEnabled) {
						tooltip = this.i18nService.get('file.sample.download.tooltip');
					} else {
						if (sampleState.sampleDisabledReason === SampleDisabledReason.FileNotObservedInOrg) {
							tooltip = this.i18nService.get('file.sample.download.fileWasNotObserved');
						} else {
							tooltip = this.i18nService.get('file.sample.download.noPermissions');
						}
					}
				}

				return {
					...buttonAction,
					disabled: disabled,
					tooltip: tooltip,
				};
			}),
			catchError(err => of(null))
		);
	}

	private async quarantineFile(file: File) {
		if (this._quarantineStatus.disabled === false) {
			const { confirmed } = await this.fileActionsService.quarantineFile(file, true);

			if (confirmed) {
				this._onActionStatusRefresh$.next(ActionRefreshReason.OnQuarantine);
				return;
			}
		} else {
			this.dialogsService.showSnackbar({
				text: this._quarantineStatus.reason,
				icon: 'warningSolid',
				iconClassName: 'color-text-warning-dark',
				className: 'color-box-warning-light',
			});
		}

		this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
	}

	private createQuarantineActionConfig(
		file: File,
		refreshReason: ActionRefreshReason
	): Observable<ItemActionModelConfig<void>> {
		if (!this._quarantineAction) {
			this._quarantineAction = {
				id: 'quarantine',
				name: this.i18nService.get('file.quarantine.title'),
				icon: FabricIconNames.BlockedSite,
				rbac: [RbacMdeAllowedActions.remediationActions],
				rbacState: RbacControlState.disabled,
				refreshOnResolve: false,
				method: async items => {
					this.trackFileEvent('Improved file page - Quarantine file');
					this._onActionStatusRefresh$.next(ActionRefreshReason.OnQuarantineStatistics);

					this._quarantineSub && this._quarantineSub.unsubscribe();

					if (this._quarantineStatus) {
						this.quarantineFile(items[0]);
						return;
					}

					this._quarantineSub = this.fileActionsService
						.getQuarantineStatus(file)
						.subscribe(async quarantineStatus => {
							this._quarantineStatus = quarantineStatus;
							this.quarantineFile(items[0]);
						});
				},
			};
		}

		let fileActionPermissions$ = of({
			disabled: true,
			reason: this.i18nService.get('file.quarantine.tooltips.pending'),
		});

		if (
			refreshReason !== ActionRefreshReason.OnQuarantine &&
			refreshReason !== ActionRefreshReason.OnQuarantineStatistics
		) {
			if (this._quarantinePermissions) {
				fileActionPermissions$ = of(this._quarantinePermissions);
			} else {
				fileActionPermissions$ = this.fileActionsService.getQuarantinePermissions(file).pipe(
					startWith({
						disabled: true,
						reason: this.i18nService.get('file.quarantine.analyzingPermissions'),
					}),
					tap(permission => {
						this._quarantinePermissions = permission;
						this._onActionStatusRefresh$.next(ActionRefreshReason.OnQuarantinePermissionsReceived);
					} )
				);
			}
		}

		return fileActionPermissions$.pipe(
			map(quarantineStatus => ({
				...this._quarantineAction,
				tooltip: quarantineStatus.reason,
				disabled: quarantineStatus.disabled
			}))
		);
	}

	private createActionCenterActionConfig(): Observable<ItemActionModelConfig<void>> {
		return of({
			id: 'actionCenter',
			name: this.featuresService.isEnabled(Feature.NewFilePage) ?
				this.i18nService.get('file_manualActions_title') : this.i18nService.get('file.actionCenter.title'),
			icon: FabricIconNames.F12DevTools,
			rbac: [RbacMdeAllowedActions.remediationActions, RbacMdeAllowedActions.alertsInvestigation],
			rbacState: RbacControlState.disabled,
			refreshOnResolve: false,
			method: items => {
				this.featuresService.isEnabled(Feature.NewFilePage) && this.trackFileEvent('Improved file page - Manual actions');
				return this.dialogsService
					.showPanel(
						FileActionCenterPanelComponent,
						{
							headerIcon: FabricIconNames.F12DevTools,
							headerText: this.featuresService.isEnabled(Feature.NewFilePage) ?
								this.i18nService.get('file_manualActions_title') : this.i18nService.get('file.actionCenter.title'),
							type: PanelType.large,
						},
						{ file: items[0] },
					)
					.toPromise();
			},
		});
	}
}
