import {
	EntityField,
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
} from '@microsoft/paris';
import { Machine } from '../machine.entity';
import { MachineAnyRequestsActive } from './machine-any-requests-active.value-object';
import { MachineRequestPackageDownload } from './machine-package-download.value-object';
import { MachineRequest, MachineRequestBase } from './machine-request.value-object';
import { MachineRequestsState } from './machine-requests-state.value-object';
import { MachineRequestLogsDownload } from './machine-logs-download.value-object';

export class MachineExtension extends Machine {
	constructor(machine: Machine, requestGuid?: string) {
		super(machine);
		this.requestGuid = requestGuid;
	}

	@EntityField({ data: 'RequestGuid' })
	readonly requestGuid: string;
}

@EntityRelationship({
	sourceEntity: MachineExtension,
	dataEntity: MachineRequestBase,
	allowedTypes: [RelationshipType.OneToMany],
	baseUrl: ({ data: { serviceUrls } }) => serviceUrls.userRequests,
	endpoint: 'requests/latest',
	allItemsEndpointTrailingSlash: false,
	getRelationshipData: ({ machineId, requestGuid }: MachineExtension) => ({
		machineId,
		requestGuid: requestGuid || null
	}),
})
export class MachineMachineRequestLatestRelationship
	implements EntityRelationshipRepositoryType<Machine, MachineRequest> {}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: MachineRequestsState,
	allowedTypes: [RelationshipType.OneToOne],
	baseUrl: ({ data: { serviceUrls } }) => serviceUrls.userRequests,
	endpoint: 'requests/machinestate',
	allItemsEndpointTrailingSlash: false,
	getRelationshipData: (machine: Machine) => ({
		machineId: machine.machineId,
	}),
})
export class MachineMachineRequestsStateRelationship
	implements EntityRelationshipRepositoryType<Machine, MachineRequestsState> {}

@EntityRelationship({
	sourceEntity: MachineRequestBase,
	dataEntity: MachineRequestPackageDownload,
	baseUrl: ({ data: { serviceUrls } }) => serviceUrls.userRequests,
	allowedTypes: [RelationshipType.OneToOne],
	allItemsEndpointTrailingSlash: false,
	endpoint: 'requests/forensics/downloaduribyguid',
	getRelationshipData: ({ requestGuid, packageIdentity }: MachineRequest) => ({
		requestGuid,
		packageIdentity,
	}),
})
export class MachineRequestPackageDownloadRelationship
	implements EntityRelationshipRepositoryType<MachineRequest, MachineRequestPackageDownload> {}

@EntityRelationship({
	sourceEntity: MachineRequestBase,
	dataEntity: MachineRequestLogsDownload,
	baseUrl: ({ data: { serviceUrls } }) => serviceUrls.userRequests,
	allowedTypes: [RelationshipType.OneToOne],
	allItemsEndpointTrailingSlash: false,
	endpoint: 'requests/logscollection/downloaduribyguid',
	getRelationshipData: ({ requestGuid }: MachineRequest) => ({
		requestGuid,
	}),
})
export class MachineRequestLogsCollectionDownloadRelationship
	implements EntityRelationshipRepositoryType<MachineRequest, MachineRequestLogsDownload> {}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: MachineAnyRequestsActive,
	baseUrl: ({ data: { serviceUrls } }) => serviceUrls.userRequests,
	allowedTypes: [RelationshipType.OneToOne],
	allItemsEndpointTrailingSlash: false,
	endpoint: 'requests/machine/any',
	getRelationshipData: ({ machineId }: Machine) => ({
		machineId,
	}),
})
export class MachineAnyRequestsActiveRelationship
	implements EntityRelationshipRepositoryType<Machine, MachineAnyRequestsActive> {}
