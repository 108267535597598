<ng-container *ngIf="(machine$ | async) as machine">
	<div
		[ngClass]="mode === EntityDetailsMode.EntityPage ? 'wcd-full-height wcd-flex-vertical' : ''"
		data-track-component="MachineEntityDetails"
	>
		<ng-container *ngIf="mode === EntityDetailsMode.EntityPage">
			<dl>
				<ng-container *ngIf="machine.riskScore">
					<div
						class="wcd-margin-medium-bottom"
						*ngIf="!isMtp && machine.isRiskScoreImpactedByNonMdatpAlerts"
					>
						<wcd-info-box>
							<p class="wcd-font-size-s wcd-margin-none-bottom">
								{{ 'machineRiskScore.affectedByOtherIndication' | i18n }},
								<a target="_blank" [href]="getMtpDevicePageLink(machine)">
									{{ 'machineRiskScore.goToMtpLinkText' | i18n }}
								</a>
							</p>
						</wcd-info-box>
					</div>
				</ng-container>
			</dl>
		</ng-container>
		<ng-container *ngIf="mode === EntityDetailsMode.EntityPage">
			<div>
				<ng-container *ngIf="isEndpointManagementEnabled">
					<div
						class="wcd-margin-medium-bottom"
						*ngIf="shouldShowMdeRecommendationsAndNotifications(machine)"
					>
						<fab-message-bar
							*ngIf="isShowMdeAttachNotificationBar"
							[messageBarType]="info"
							[isMultiline]="true"
							[dismissable]="true"
							[dismissButtonAriaLabel]="i18nService.strings.common_close"
							(onDismiss)="hideMessageBar()">
							<dl class="wcd-font-size-s wcd-margin-none-bottom">
								<dt>{{ getMdeAttachTitle(machine) }}</dt>
								<dd><markdown>{{ getMdeAttachError(machine) }}</markdown></dd>
							</dl>
						</fab-message-bar>
					</div>
				</ng-container>
			</div>
		</ng-container>
		<onboarding-status-message [device]="machine$ | async"></onboarding-status-message>
		<ad-iot-up-sell-message [device]="machine$ | async"></ad-iot-up-sell-message>
		<ng-container *ngIf="mode === EntityDetailsMode.EntityPage; else sidePanelSections">
			<ng-container *ngTemplateOutlet="tags"></ng-container>
			<wcd-collapsible-section
				label="{{ 'machines.entityDetails.sections.securityInfo' | i18n }}"
				[sectionId]="collapsibleID.SecurityInfo"
				*ngIf="collapsibleSections; else alerts"
			>
				<ng-container *ngTemplateOutlet="alerts"></ng-container>
			</wcd-collapsible-section>
			<wcd-collapsible-section
				label="{{ 'machines.entityDetails.sections.details' | i18n }}"
				[sectionId]="collapsibleID.Details"
				*ngIf="collapsibleSections; else deviceDetails"
			>
				<ng-container *ngTemplateOutlet="deviceDetails"></ng-container>
			</wcd-collapsible-section>

			<ng-container *ngIf="machine.machineId && isTvmHardwareAndFirmwareEnabled">
				<collapsible-asset-hardware-and-firmware [machineId]="machine.machineId">
				</collapsible-asset-hardware-and-firmware>
			</ng-container>

			<wcd-collapsible-section
				label="{{ 'machines.entityDetails.sections.deviceManagement' | i18n }}"
				[sectionId]="collapsibleID.DeviceManagement"
				*ngIf="shouldShowDeviceManagementCollapsible(machine)"
			>
				<ng-container *ngTemplateOutlet="deviceManagement"></ng-container>
			</wcd-collapsible-section>
			<wcd-collapsible-section
				label="{{ 'machines.entityDetails.sections.networkActivity' | i18n }}"
				[sectionId]="collapsibleID.NetworkActivity"
				*ngIf="machine.firstSeen || machine.lastSeen"
			>
				<ng-container *ngTemplateOutlet="networkActivity"></ng-container>
			</wcd-collapsible-section>
			<wcd-collapsible-section
				label="{{ 'machines.entityDetails.sections.directoryData' | i18n }}"
				[sectionId]="collapsibleID.DirectoryData"
				*ngIf="
					collapsibleSections && showCollapsibleDirectoryDataSection(machine);
					else directoryData
				"
			>
				<ng-container *ngTemplateOutlet="directoryData"></ng-container>
			</wcd-collapsible-section>
		</ng-container>

		<ng-template #sidePanelSections>
			<wcd-collapsible-section
				label="{{ 'machines.entityDetails.sections.details' | i18n }}"
				[sectionId]="collapsibleID.Details"
				*ngIf="collapsibleSections; else deviceDetails"
			>
				<ng-container *ngTemplateOutlet="deviceDetails"></ng-container>
			</wcd-collapsible-section>
			<wcd-collapsible-section
				label="{{ 'machines.entityDetails.sections.directoryData' | i18n }}"
				[sectionId]="collapsibleID.DirectoryData"
				*ngIf="
					collapsibleSections && showCollapsibleDirectoryDataSection(machine)
				"
			>
				<ng-container *ngTemplateOutlet="directoryData"></ng-container>
			</wcd-collapsible-section>
			<wcd-collapsible-section
				label="{{ 'alerts.active' | i18n }}"
				[sectionId]="collapsibleID.ActiveAlerts"
				(isExpandedChange)="$event && updateTableView()"
				*ngIf="collapsibleSections; else alerts"
			>
				<ng-container *ngTemplateOutlet="alerts"></ng-container>
			</wcd-collapsible-section>
		</ng-template>

		<ng-container *ngIf="withUsers && mode !== EntityDetailsMode.EntityPage && machine.senseMachineId">
			<ng-container *ngLet="(userDetails$ | async) as userDetails">
				<wcd-collapsible-section
					[sectionId]="collapsibleID.UserDetails"
					*ngIf="collapsibleSections; else users"
					[label]="
						userDetails?.interactiveOrRemoteUsersCount
							? ('machines.entityDetails.fields.loggedOnUsers.title.count'
							  | i18n: { count: userDetails?.interactiveOrRemoteUsersCount })
							: ('machines.entityDetails.fields.loggedOnUsers.title.empty' | i18n)
					"
				>
					<ng-container *ngTemplateOutlet="users"></ng-container>
				</wcd-collapsible-section>
			</ng-container>
		</ng-container>

		<!--		templates		-->

		<ng-template #deviceDetails>
			<device-details
				[machine]="machine$ | async"
				[includeMachineName]="includeMachineName"
				[isEntityPage]="mode === EntityDetailsMode.EntityPage"
			></device-details>
		</ng-template>
		<ng-template #networkActivity>
			<dl>
				<ng-container *ngIf="machine.firstSeen">
					<dt>{{ 'machines.entityDetails.fields.firstSeen.title' | i18n }}</dt>
					<dd>{{ machine.firstSeen | date: 'medium' }}</dd>
				</ng-container>

				<ng-container *ngIf="machine.lastSeen">
					<dt>{{ 'machines.entityDetails.fields.lastSeen.title' | i18n }}</dt>
					<dd>{{ machine.lastSeen | date: 'medium' }}</dd>
				</ng-container>

				<ng-container *ngIf="(seenBy$ | async) as seenBy">
					<dt>{{ 'machine_details_seenBy_title' | i18n }}</dt>
					<ng-container *ngIf="seenBy.lastSeenDevice">
						<dd
							class="no-wrap long-field"
							[attr.title]="seenBy.lastSeenDevice.deviceName || seenBy.lastSeenDevice.deviceId"
						>
							<a
								[routerLink]="getDeviceLink(seenBy.lastSeenDevice.deviceId)"
								class="btn-inline btn-link no-padding"
								role="link"
							>
								<wcd-shared-icon iconName="{{ FabricIconNames.System }}"></wcd-shared-icon>
								{{ seenBy.lastSeenDevice.deviceName || seenBy.lastSeenDevice.deviceId }}
							</a>
						</dd>
					</ng-container>
					<dd *ngIf="!seenBy.isAuthorizedToSeeSeenByData" class="wcd-flex">
						<wcd-shared-icon iconName="{{ FabricIconNames.Warning }}" class="ms-fontColor-highSeverity wcd-padding-small-right"></wcd-shared-icon>
						<div>
							{{ 'machine_details_seenBy_unAuthorizedError' | i18n }}
							<div>
								<a href="https://docs.microsoft.com/en-us/microsoft-365/security/defender-endpoint/machine-groups?view=o365-worldwide"
									target="_blank"
									rel="noopener noreferrer">
									{{ 'machine_details_seenBy_unAuthorizedError_link' | i18n }}
								</a>
							</div>
						</div>
					</dd>
				</ng-container>

				<ng-container *ngIf="isMdiDevice(machine)">
					<dt>{{ 'machine_details_seenBy_title' | i18n }}</dt>
					<dd
						class="no-wrap long-field"
						title="{{ 'machine_details_seenBy_domainController' | i18n }}"
					>
						{{ 'machine_details_seenBy_domainController' | i18n }}
					</dd>
				</ng-container>
			</dl>
		</ng-template>
		<ng-template #directoryData>
				<div [class.two-key-values-columns]="mode === EntityDetailsMode.EntityPage" *ngIf="isMtp" >
					<ng-container *ngFor="let directoryDataProperty of itpDirectoryDataProperties">
					<dl>
						<dt>{{ directoryDataProperty.title }}</dt>
						<dd>
							<button
								*ngIf="directoryDataProperty.exposeProperty(machine)"
								(click)="directoryDataProperty.seeAllOnClick(machine)"
								data-track-id="directoryDataProperty.dataTrackId"
								data-track-type="Button"
								class="btn-inline btn-link no-padding wcd-font-size-m"
							>
								{{ directoryDataProperty.seeAllText(machine) }}
							</button>
							<div class="wcd-font-size-m" *ngIf="!directoryDataProperty.exposeProperty(machine)">
								{{ directoryDataProperty.noDataText() }}
							</div>
						</dd>
					</dl>
					</ng-container>
				</div>
		</ng-template>
		<ng-template #alerts>
			<ng-container *ngLet="(alertSeveritySummary$ | async) as alertSeverities">
				<ng-container *ngIf="mode !== EntityDetailsMode.EntityPage; else alertsTotalOnly">
					<fab-shimmer [isDataLoaded]="alertSeverities">
						<div>
							<alerts-severity-incident-summary
								*ngIf="alertSeverities"
								[alertsSeveritySummary]="alertSeverities"
							></alerts-severity-incident-summary>
							<ng-container *ngLet="(alerts$ | async) as alerts">
								<wcd-datatable
									*ngIf="alerts?.length"
									[columns]="alertsColumns"
									[items]="alerts"
									(itemClick)="viewAlert($event)"
									[selectEnabled]="false"
								>
								</wcd-datatable>
								<button
									*ngIf="alertSeverities && alertSeverities.alertsCount > MAX_ALERTS"
									(click)="onShowMoreAlerts()"
									data-track-id="MachineEntityDetails_ShowMoreAlerts"
									data-track-type="Button"
									class="btn-inline btn-link no-padding"
								>
									{{ 'alerts.showMore' | i18n }}
								</button>
							</ng-container>
						</div>
					</fab-shimmer>
				</ng-container>
				<ng-template #alertsTotalOnly>
					<div class="two-key-values-columns">
						<ng-container *ngIf="hasMdeLicenseWorkloads">
							<dl>
								<dt>{{ 'machines.entityDetails.fields.activeIncidents.title' | i18n }}</dt>
								<dd *ngLet="(incidentsCount$ | async) as incidentsCount">
									<ng-container *ngIf="isLoadingIncidents; else machineIncidents">
										<i class="loader-icon"></i
										>{{ 'machines.entityDetails.fields.activeIncidents.loading' | i18n }}
									</ng-container>
									<ng-template #machineIncidents>
										{{ incidentsCount }}
									</ng-template>
								</dd>
							</dl>
						</ng-container>
						<ng-container *ngIf="hasMdeLicenseWorkloads">
							<dl>
								<dt>
									<wcd-help [isLarge]="true" [text]="'machines.activeAlerts' | i18n">{{
										'machines.entityDetails.fields.activeAlerts.title' | i18n
									}}</wcd-help>
								</dt>
								<dd>{{ alertSeverities ? alertSeverities.alertsCount : '-' }}</dd>
							</dl>
						</ng-container>
						<ng-container *ngIf="withExposureLevel && (!p1LicenseInMixedLicenseMode || isTvmPremiumEnabled) && machine.senseMachineId && machine.exposureScore">
							<dl>
								<dt>
									<wcd-help [isLarge]="true" [text]="'machines.exposureLevel' | i18n">{{
										'machineExposureLevel.title' | i18n
									}}</wcd-help>
								</dt>
								<dd>
									<wcd-shared-icon
										[iconName]="machine.exposureScore.icon"
										[ngClass]="'color-text-' + machine.exposureScore.className"
										class="small-icon"
									></wcd-shared-icon>
									{{ i18nService.strings['machine_details_exposureScore_'+ machine.exposureScore.id] || machine.exposureScore.name }}
								</dd>
							</dl>
						</ng-container>
						<ng-container *ngIf="machine.riskScore && hasMdeLicenseWorkloads">
							<dl>
								<dt>
									<wcd-help [isLarge]="true" [text]="'machines.riskLevel' | i18n">{{
										'machineRiskScore.title' | i18n
									}}</wcd-help>
								</dt>
								<dd>
									<span
										class="wcd-severity"
										[ngClass]="
											'wcd-severity-' + machine.riskScore.id.toLocaleLowerCase()
										"
									></span>
									{{ i18nService.strings['machine_details_machineRiskScore_'+ machine.riskScore.id] || machine.riskScore.name }}
								</dd>
							</dl>
						</ng-container>
					</div>
				</ng-template>
				<ng-container *ngIf="!isMtp">
					<ng-container *ngLet="(aatpAlertsDetails$ | async) as aatpAlertsDetails">
						<dl class="wcd-margin-top">
							<dt>{{ 'machines.entityDetails.fields.azureAtpAlerts.title' | i18n }}</dt>
							<dd>
								<wcd-contents-state [contentState]="aatpAlertsContentState">
									<i loading class="loader-icon"></i>
									{{ 'machines.entityDetails.fields.azureAtpAlerts.loading' | i18n }}
									<ng-container complete *ngIf="aatpAlertsDetails">
										<a
											data-track-id="AatpAlertsDetails"
											data-track-type="ExternalLink"
											[href]="aatpAlertsDetails.url"
											target="_blank"
											>{{
												'machines.entityDetails.fields.azureAtpAlerts.details'
													| i18n: { count: aatpAlertsDetails.count }
											}}</a
										>
									</ng-container>
									<ng-container empty>
										<ng-container [ngTemplateOutlet]="notFoundOrEmpty"></ng-container>
									</ng-container>
									<ng-container error>
										<ng-container [ngTemplateOutlet]="notFoundOrEmpty"></ng-container>
									</ng-container>

									<ng-template #notFoundOrEmpty>{{
										'machines.entityDetails.fields.azureAtpAlerts.notFound' | i18n
									}}</ng-template>
								</wcd-contents-state>
							</dd>
						</dl>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-template>
		<ng-template #users>
			<ng-container *ngLet="(userDetails$ | async) as users">
				<span *ngIf="isLoadingUsers; else usersDetails">
					<i class="loader-icon"></i>
					{{ 'machines.entityDetails.fields.loggedOnUsers.loading' | i18n }}
				</span>

				<ng-template #usersDetails>
					<div *ngIf="users?.hasUsers; else noUsers">
						<ng-container *ngIf="users.mostPrevalentUser; else noMostPrevalentAccount">
							<div *ngIf="users.isSingleUser; else multipleUsers">
								<ng-container
									*ngTemplateOutlet="
										accountDetails;
										context: { account: users.mostPrevalentUser }
									"
								></ng-container>
							</div>
							<ng-template #multipleUsers>
								<dl class="wcd-margin-bottom">
									<dt>
										{{
											'machines.entityDetails.fields.loggedOnUsers.details.mostLogons'
												| i18n
										}}
									</dt>
									<dd>
										<ng-container
											*ngTemplateOutlet="
												accountDetails;
												context: { account: users.mostPrevalentUser }
											"
										></ng-container>
									</dd>
									<dt>
										{{
											'machines.entityDetails.fields.loggedOnUsers.details.leastLogons'
												| i18n
										}}
									</dt>
									<dd>
										<ng-container
											*ngTemplateOutlet="
												accountDetails;
												context: { account: users.leastPrevalentUser }
											"
										></ng-container>
									</dd>
									<ng-container *ngIf="alert?.user">
										<dt>
											<wcd-help
												[isLarge]="true"
												[text]="'alerts.offendingProcessLaunch.description' | i18n"
											>
												<span class="nowrap">{{
													'alerts.offendingProcessLaunch.title.noWrap' | i18n
												}}</span>
												{{
													'alerts.offendingProcessLaunch.title.possibleWrap' | i18n
												}}
											</wcd-help>
										</dt>
										<dd>
											<ng-container
												*ngTemplateOutlet="
													accountDetails;
													context: { account: alert.user }
												"
											></ng-container>
										</dd>
									</ng-container>
								</dl>
							</ng-template>
						</ng-container>

						<ng-template #noMostPrevalentAccount>
							<div class="wcd-margin-bottom subtle">
								{{ 'machines.noInteractiveLogons' | i18n }}
							</div>
						</ng-template>

						<button
							*ngIf="users.loggedOnUsersCount"
							data-track-id="LoggedOnUsersClick"
							data-track-type="Button"
							(click)="loggedOnUsersClick.emit(machine)"
							class="btn-inline btn-link no-padding"
						>
							{{
								'machines.entityDetails.fields.loggedOnUsers.allUsersLink'
									| i18n: { count: users.loggedOnUsersCount }
							}}
						</button>
					</div>

					<ng-template #noUsers>
						<div class="subtle">
							{{ 'machines.entityDetails.fields.loggedOnUsers.empty' | i18n }}
						</div>
					</ng-template>
				</ng-template>
			</ng-container>
		</ng-template>
		<ng-template #accountDetails let-account="account">
			<div class="wcd-margin-bottom wcd-flex-horizontal">
				<user-icon
					[userName]="account.accountName"
					class="wcd-flex-none wcd-padding-small-right"
				></user-icon>
				<div class="wcd-flex-1 wcd-flex-center-vertical">
					<div>
						<ng-container
							*ngLet="
								globalEntityTypesService
									.getEntityType('user')
									.getNavigationModel(account) as routeLink
							"
						>
							<route-link
								*ngIf="routeLink && (isUserLinkAllowed$ | async); else userTextOnly"
								[routeLink]="routeLink"
							>
								{{ account.accountName }}
							</route-link>
						</ng-container>

						<ng-template #userTextOnly>{{ account.accountName }}</ng-template>
						<div *ngIf="account.isLocalAdmin">
							{{ 'users.entityDetails.sections.details.role.values.localAdmin' | i18n }}
						</div>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #tags>
			<ng-container *ngIf="(tagsWithPseudoTags$ | async) as tagsWithPseudoTags">
				<wcd-collapsible-section
					[label]="'common.tags' | i18n"
					[sectionId]="collapsibleID.Tags"
				>
					<div class="wcd-padding-largeMedium-bottom" *ngIf="tagsWithPseudoTags?.length > 0; else noTagsFound">
						<tags-list [multiline]="true" [tags]="tagsWithPseudoTags" [editable]="false"></tags-list>
					</div>
					<ng-template #noTagsFound>
						{{'machine.no.tags.found' | i18n}}
					</ng-template>
				</wcd-collapsible-section>
			</ng-container>
		</ng-template>
		<ng-template #deviceManagement>
			<dl>
				<div
					class="wcd-margin-medium-bottom"
					*ngIf="shouldShowMdeNotes(machine)"
				>
					<fab-message-bar
						[messageBarType]="info"
						[isMultiline]="true">
						<div class="wcd-font-size-s wcd-margin-none-bottom">
							<dt>{{ getMdeAttachTitle(machine) }}</dt>
							<markdown>{{ getMdeAttachError(machine) }}</markdown>
						</div>
					</fab-message-bar>
				</div>
				<ng-container>
					<dt>{{ 'machines.entityDetails.fields.managedBy.title' | i18n }}</dt>
					<dd>{{ getManagedByString(machine) }}</dd>

					<dt>
						<wcd-help [isLarge]="true" [wcdTooltipAllowHtmlRendering]="true" [text]="'machines_entityDetails_fields_enrollmentStatus_tooltip' | i18n">
							{{ 'machines.entityDetails.fields.enrollmentStatus.title' | i18n }}
						</wcd-help>
					</dt>
					<dd>{{ getEnrollmentStatusString(machine) }}</dd>
				</ng-container>
			</dl>
		</ng-template>
	</div>
</ng-container>
