import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './_base/components/admin.component';
import {
	getSystemExclusionPageTitleKey,
	systemExclusionsRoutes,
} from '../@entities/system_exclusions/system-exclusions.module';
import { FolderExclusionsComponent } from './folder_exclusions/components/folder-exclusions.component';
import { AdvancedSettingsComponent } from './advanced/components/advanced-settings.component';
import { CanDeactivateForm } from '../shared/guards/can-deactivate-form.guard';
import { RbacEntitiesModule } from '../@entities/rbac/rbac-entities.module';
import { UserRolesDataviewComponent } from '../@entities/rbac/components/user_roles/user-roles.dataview';
import { SuppressionRulesDataviewComponent } from '../@entities/suppression_rules/components/suppression-rules.dataview';
import { EndpointManagementPageComponent } from '../endpoint_management/components/endpoint-management-page.component';
import { IntegrationSettingsComponent } from './integration-settings/integration-settings.component';
import { AirsEnabledGuard, EnableAutomatedIrComponent, Feature } from '@wcd/config';
import { NotificationRulesDataviewComponent } from './notification_rules/components/notification-rules.dataview';
import { ComponentLoading } from '../shared/main/services/main-app-state.service';
import { ScheduledHuntingDataviewComponent } from '../@entities/scheduled_hunting/components/scheduled-hunting.dataview';
import {
	MdeAllowedActionsGuard,
	AppConfigValueGuard,
	AuthenticatedGuard,
	ExposedToAllMachineGroupsGuard,
	FeatureEnabledGuard,
	IsServiceNowIntegrationEnabled,
	OnboardGuard,
	MdatpGuard,
	FlavorGuard,
	UnifiedRbacPermission,
	UnifiedRbacGuard,
	TvmLicenseMigrationFlavorGuard,
	TvmLicenseOrFlavorGuard,
	TvmLicenseNoMdeGuard,
	MdatpOrTvmLicenseGuard,
	IsPublicEnvironment,
} from '@wcd/auth';
import { GeneralSettingsComponent } from '../admin/general/general-settings.component';
import { getCustomTiIndicatorPageTitleKey } from '../@entities/custom_ti_indicators/custom-ti-indicators.module';
import { HuntingRule, SuppressionRule, MdeUserRoleActionEnum, MtpWorkload } from '@wcd/domain';
import { ServiceNowProvisioningComponent } from '../@entities/@tvm/remediation/itsm-provisioning/components/servicenow-provisioning.component';
import { AuthenticatedScansDataviewComponent } from '../@entities/@tvm/authenticated-scans/components/authenticated-scans.dataview.component';
import { MachineGroupsRemediationLevelDataviewComponent } from '../@entities/rbac/components/machine_groups/machine-groups-remediation-level.dataview';
import { MachineGroupsDataviewComponent } from '../@entities/rbac/components/machine_groups/machine-groups.dataview';
import { AuthCodeRetrievalComponent } from './auth-code-retrieval/auth-code-retrieval.component';
import { HistorySafeRedirectComponent } from '../shared/components/history-safe-redirect.component';
import { PortalRedirectionComponent } from './portal-redirection/components/portal-redirection.component';
import { SccMdeAttachSectionComponent } from './mde-attach/components/mde-attach.component';
import { LogicalOperator } from '@wcd/shared';
import { AppFlavorConfig } from '@wcd/scc-common';
import { TvmLicenseType } from '@wcd/scc-common';
import { VulnerabilityNotificationRulesDataviewComponent } from '../@entities/@tvm/vulnerability_notifications_rules/components/vulnerability-notification-rules.dataview';
import { EndpointSettingsLicensesTabComponent } from './smb/components/licenses-tab.component';
import {EndpointSmbSettingsLicensesTabComponent} from "./smb/components/smb-licenses-tab.component";

const adminSections = {
	api: 'settings_section_apis',
	general: 'settings_section_general',
	machineManagement: 'settings_section_deviceManagement',
	permissions: 'settings_section_permissions',
	rules: 'settings_section_rules',
	dev: 'settings_section_development',
	itsmProvisioning: 'settings_section_itServiceManagement',
	networkGear: 'settings_section_networkAssessments',
	mdeAttach: 'settings_section_mde_attach',
};

const availableAdminRoutesChildren: Routes = [
	{
		path: 'general',
		component: GeneralSettingsComponent,
		data: {
			pageTitleKey: 'settings_navItem_dataRetention',
			section: adminSections.general,
			/*features: [
				Feature.ResponseBlacklist,
				Feature.OfficeAtpIntegration,
				Feature.SkypeIntegration,
				Feature.UserDetails,
			],*/
		},
	},
	{
		path: 'licenses',
		component: EndpointSettingsLicensesTabComponent,
		canActivate: [FlavorGuard],
		data: {
			pageTitleKey: 'settings_navItem_licenses',
			section: adminSections.general,
			flavorConfig: AppFlavorConfig.settings.licenses,
			appConfigValidator: IsPublicEnvironment
		},
	},
	{
		path: 'smb_licenses',
		component: EndpointSmbSettingsLicensesTabComponent,
		canActivate: [FlavorGuard, FeatureEnabledGuard],
		data: {
			pageTitleKey: 'settings_navItem_licenses',
			section: adminSections.general,
			features: [Feature.EnableSmbLicensesPage],
			flavorConfig: AppFlavorConfig.settings.smbLicenses,
		},
	},
	{
		path: 'alert_notifications',
		component: NotificationRulesDataviewComponent,
		canActivate: [FeatureEnabledGuard],
		data: {
			pageTitleKey: 'settings_navItem_alertNotifications',
			section: adminSections.general,
			features: [Feature.EmailNotifications],
			disableFeatures: [Feature.TvmVulnerabilityNotifications],
		},
	},
	{
		path: 'email_notifications',
		loadChildren: 'app/admin/email-notifications/email-notifications.module#EmailNotificationsModule',
		canActivate: [FeatureEnabledGuard, FlavorGuard],
		data: {
			pageTitleKey: 'settings_navItem_emailNotifications',
			section: adminSections.general,
			flavorConfig: AppFlavorConfig.settings.mdeWithWorkloads,
			features: [Feature.TvmVulnerabilityNotifications],
			loadingComponentConfig: {
				descriptionKey: 'loading_email_notifications',
				transparent: true,
			},
		},
	},
	{
		path: 'vulnerability_email_notifications',
		redirectTo: 'email_notifications/vulnerabilities',
		data: {
			pageTitleKey: 'settings_navItem_vulnerabilityEmailNotifications',
			hidden: true,
		},
	},
	{
		path: 'vulnerability-notifications',
		component: VulnerabilityNotificationRulesDataviewComponent,
		canActivate: [TvmLicenseNoMdeGuard],
		data: {
			pageTitleKey: 'settings_navItem_emailNotifications',
			section: adminSections.general,
			tvmLicenseType: TvmLicenseType.TvmBasic,
		},
	},
	// {
	// 	path: 'automation_notifications',
	// 	component: AirsNotificationRulesComponent,
	// 	canActivate: [AirsEnabledGuard],
	// 	data: {
	// 		pageTitle: 'Automation notifications',
	// 		section: adminSections.general,
	// 		features: [Feature.AutoInvestigationEnabled],
	// 		enableAirsRoute: [
	// 			'/preferences2/enable_airs',
	// 			{ messageClass: 'relative enable-auto-ir-investigation-message' },
	// 		],
	// 	},
	// },
	{
		path: 'integration',
		component: IntegrationSettingsComponent,
		data: {
			pageTitleKey: 'settings_navItem_advancedFeatures',
			section: adminSections.general,
			badgeId: 'settings',
		},
	},
	{
		path: 'auth_code_retrieval',
		component: AuthCodeRetrievalComponent,
		data: {
			hidden: true,
		},
	},
	{
		path: 'user_roles',
		canActivate: [ExposedToAllMachineGroupsGuard, TvmLicenseOrFlavorGuard],
		component: UserRolesDataviewComponent,
		data: {
			pageTitleKey: 'settings_navItem_roles',
			section: adminSections.permissions,
			features: [Feature.RbacUserRoles],
			disableIfNoAccessToAllGroups: true,
			flavorConfig: AppFlavorConfig.settings.permissions,
			tvmLicenseType: TvmLicenseType.TvmBasic,
			hideInSmb: true
		},
	},
	{
		path: 'machine_groups',
		canActivate: [ExposedToAllMachineGroupsGuard, TvmLicenseOrFlavorGuard, UnifiedRbacGuard],
		component: MachineGroupsDataviewComponent,
		data: {
			pageTitleKey: 'settings_navItem_deviceGroups',
			section: adminSections.permissions,
			features: [Feature.RbacMachineGroups],
			disableIfNoAccessToAllGroups: true,
			flavorConfig: AppFlavorConfig.settings.deviceGroups,
			UnifiedRbacPermissionGuardConfig: {
				UnifiedRbacPermission: UnifiedRbacPermission.ConfigurationAuthorizationRead,
				Workload: MtpWorkload.Mdatp,
			},
			tvmLicenseType: TvmLicenseType.TvmBasic,
			hideInSmb: true
		},
	},
	{
		path: 'siem',
		canActivate: [FlavorGuard],
		loadChildren: 'app/admin/connectors/siem/siem-settings.module#SiemSettingsModule',
		data: {
			pageTitleKey: 'settings_navItem_siem',
			section: adminSections.api,
			features: [Feature.SiemOnboardingMssp],
			loadingComponentConfig: {
				id: ComponentLoading.settings,
			},
			flavorConfig: AppFlavorConfig.settings.mdeWithWorkloads,
		},
	},
	{
		path: 'legacy',
		redirectTo: '/preferences/siemconnector',
		data: {
			id: 'preferences.siemconnector',
			pageTitleKey: 'settings_navItem_siem',
			isLegacy: true,
			section: adminSections.api,
			features: [Feature.SiemOnboarding],
			disableFeatures: [Feature.SiemOnboardingMssp],
		},
	},
	// ToDo: remove once Angular package is deprecated.
	{
		path: 'scheduled_hunting',
		component: ScheduledHuntingDataviewComponent,
		canActivate: [AuthenticatedGuard, OnboardGuard, FlavorGuard, MdeAllowedActionsGuard],
		data: {
			entity: HuntingRule,
			breadcrumbsConfig: {
				resetListOnEnter: true,
				show: false,
			},
			pageTitleKey: 'settings_navItem_customDetections',
			section: adminSections.rules,
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData,
			},
			flavorConfig: AppFlavorConfig.settings.hunting,
		},
	},
	{
		path: 'alert_suppression',
		component: SuppressionRulesDataviewComponent,
		canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard, FlavorGuard],
		data: {
			entity: SuppressionRule,
			breadcrumbsConfig: {
				resetListOnEnter: true,
				show: false,
			},
			pageTitleKey: 'settings_navItem_alertSuppression',
			section: adminSections.rules,
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData,
			},
			flavorConfig: AppFlavorConfig.settings.mdeWithWorkloads,
		},
	},
	{
		path: 'custom_ti_indicators',
		loadChildren:
			'app/@entities/custom_ti_indicators/custom-ti-indicators.module#CustomTiIndicatorsModule',
		canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard, MdatpOrTvmLicenseGuard],
		data: {
			getPageTitleKey: getCustomTiIndicatorPageTitleKey,
			section: adminSections.rules,
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData | MdeUserRoleActionEnum.tvmViewData,
			},
			tvmLicenseType: TvmLicenseType.TvmBasic,
			features: [Feature.TiIndicators],
		},
	},
	{
		path: 'system_exclusions',
		children: systemExclusionsRoutes,
		canActivate: [FlavorGuard, AirsEnabledGuard, ExposedToAllMachineGroupsGuard, MdeAllowedActionsGuard],
		data: {
			getPageTitleKey: getSystemExclusionPageTitleKey,
			section: adminSections.rules,
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData,
			},
			enableAirsRoute: [
				'/preferences2/enable_airs',
				{ messageClass: 'relative enable-auto-ir-investigation-message' },
			],
			disableIfNoAccessToAllGroups: true,
			flavorConfig: AppFlavorConfig.settings.autoIR,
		},
	},
	{
		path: 'web_content_filtering_policy',
		loadChildren:
			'app/@entities/web_content_filtering/web-content-filtering-policy.module#WebContentFilteringPoliciesModule',
		canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard, FlavorGuard],
		data: {
			pageTitleKey: 'settings_navItem_webContentFiltering',
			section: adminSections.rules,
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData,
			},
			features: [Feature.WebContentFilteringPolicy],
			flavorConfig: AppFlavorConfig.settings.mdeWithWorkloads,
		},
	},
	{
		path: 'advanced',
		component: AdvancedSettingsComponent,
		canActivate: [FlavorGuard, AirsEnabledGuard, ExposedToAllMachineGroupsGuard, MdeAllowedActionsGuard],
		canDeactivate: [CanDeactivateForm],
		data: {
			pageTitleKey: 'settings_navItem_automationUploads',
			section: adminSections.rules,
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData,
			},
			enableAirsRoute: [
				'/preferences2/enable_airs',
				{ messageClass: 'relative enable-auto-ir-investigation-message' },
			],
			disableIfNoAccessToAllGroups: true,
			flavorConfig: AppFlavorConfig.settings.autoIR,
		},
	},
	{
		path: 'folder_exclusions',
		component: FolderExclusionsComponent,
		canActivate: [FlavorGuard, AirsEnabledGuard, ExposedToAllMachineGroupsGuard, MdeAllowedActionsGuard],
		data: {
			pageTitleKey: 'settings_navItem_automationFolderExclusions',
			section: adminSections.rules,
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.viewData,
			},
			enableAirsRoute: [
				'/preferences2/enable_airs',
				{ messageClass: 'relative enable-auto-ir-investigation-message' },
			],
			disableIfNoAccessToAllGroups: true,
			flavorConfig: AppFlavorConfig.settings.autoIR,
		},
	},
	{
		path: 'configuration_management2',
		component: SccMdeAttachSectionComponent,
		canActivate: [FlavorGuard],
		data: {
			pageTitleKey: 'settings_navItem_mdeAttach',
			section: adminSections.mdeAttach,
			flavorConfig: AppFlavorConfig.settings.mdeWithWorkloads,
		},
	},
	{
		path: 'onboarding',
		component: EndpointManagementPageComponent,
		data: {
			pageTitleKey: 'settings_navItem_onboarding',
			section: adminSections.machineManagement,
		},
	},
	{
		path: 'offboarding',
		component: EndpointManagementPageComponent,
		data: {
			pageTitleKey: 'settings_navItem_offboarding',
			section: adminSections.machineManagement,
		},
	},
	{
		path: 'enable_airs',
		component: EnableAutomatedIrComponent,
		data: {
			hidden: true,
		},
	},
	{
		path: 'servicenow',
		component: ServiceNowProvisioningComponent,
		canActivate: [MdeAllowedActionsGuard, AppConfigValueGuard],
		data: {
			pageTitleKey: 'settings_navItem_serviceNow',
			section: adminSections.itsmProvisioning,
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.tvmViewData,
			},
			appConfigValidator: IsServiceNowIntegrationEnabled,
		},
	},
	{
		path: 'network_assessments_assessment_jobs',
		canActivate: [FeatureEnabledGuard, TvmLicenseMigrationFlavorGuard],
		component: AuthenticatedScansDataviewComponent,
		data: {
			pageTitleKey: 'settings_navItem_assessmentJobs',
			section: adminSections.networkGear,
			features: [Feature.TvmNetworkScan],
			flavorConfig: AppFlavorConfig.settings.networkAssessments,
			tvmLicenseType: TvmLicenseType.TvmBasic,
		},
	},
	{
		path: 'remediation_levels',
		canActivate: [FeatureEnabledGuard, FlavorGuard, MdeAllowedActionsGuard],
		component: MachineGroupsRemediationLevelDataviewComponent,
		data: {
			features: [Feature.MachineGroupsRemediationLevelEdit],
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.securitySettings,
			},
			pageTitleKey: 'settings_navItem_autoRemediation',
			section: adminSections.general,
			flavorConfig: AppFlavorConfig.settings.tvm,
		},
	},
	{
		path: 'portal_redirection',
		canActivate: [FeatureEnabledGuard, FlavorGuard],
		component: PortalRedirectionComponent,
		data: {
			features: [Feature.MdatpPortalRedirection, Feature.PortedSccPages],
			featuresLogicalOperator: LogicalOperator.And,
			pageTitleKey: 'settings_navItem_portalRedirection',
			section: adminSections.general,
			flavorConfig: AppFlavorConfig.settings.portalRedirect,
		},
	},
	{
		path: '',
		component: HistorySafeRedirectComponent,
		data: {
			redirectTo: 'general',
		},
		pathMatch: 'full',
	},
];

export const adminRoutes: Routes = [
	{
		path: '',
		component: AdminComponent,
		data: {
			pageTitleIcon: 'settings',
			pageTitle: 'Administration',
		},
		children: availableAdminRoutesChildren,
	},
];

@NgModule({
	imports: [RbacEntitiesModule, RouterModule.forChild(adminRoutes)],
	exports: [RouterModule],
})
export class AdminRoutingModule {}
