var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { PendingActionDecision } from '../remediation/pending_actions/pending-action-decision.entity';
import { ActionBaseEntity } from './action-base.entity';
import { mapActionFromApiV2ToActionHistory } from './action-center-api-v2.converter';
import { compact } from 'lodash-es';
var ɵ0 = function (_, query) {
    if (query.where['useActionCenterApiV2']) {
        return 'actioncenterui/history-actions';
    }
    return query.where && query.where['useMtpApi'] ? 'MtpResponseActions' : 'ResponseActions';
}, ɵ1 = function (config, query) {
    var useActionCenterApiV2 = query && query.where && query.where['useActionCenterApiV2'];
    return useActionCenterApiV2
        ? config.data.serviceUrls.automatedIr
        : config.data.serviceUrls.threatIntel;
}, ɵ2 = function (data, _, query) {
    if (query && query.where['useActionCenterApiV2']) {
        // convert data from the new action center service format to the model format
        var results = data && data.Results;
        var convertedData = results &&
            (Array.isArray(results) ? results : [results]).map(function (action) {
                return mapActionFromApiV2ToActionHistory(action);
            });
        return { count: data.Count, results: compact(convertedData) };
    }
    return data;
}, ɵ3 = function (decision) {
    return decision && decision.toLowerCase();
};
var ActionHistory = /** @class */ (function (_super) {
    __extends(ActionHistory, _super);
    function ActionHistory() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ActionHistory.prototype, "isUndoable", {
        get: function () {
            return this.undoState === 'undoable';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionHistory.prototype, "isUndone", {
        get: function () {
            return this.undoState === 'undone';
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'DecidedBy' }),
        __metadata("design:type", String)
    ], ActionHistory.prototype, "decidedBy", void 0);
    __decorate([
        EntityField({
            data: 'ActionDecision',
            parse: ɵ3,
        }),
        __metadata("design:type", PendingActionDecision)
    ], ActionHistory.prototype, "decision", void 0);
    __decorate([
        EntityField({
            data: 'AdditionalFields.actions.undo.state',
        }),
        __metadata("design:type", String)
    ], ActionHistory.prototype, "undoState", void 0);
    __decorate([
        EntityField({
            data: 'AdditionalFields.raw_command',
        }),
        __metadata("design:type", String)
    ], ActionHistory.prototype, "rawCommand", void 0);
    __decorate([
        EntityField({
            data: 'OriginalActionComment',
        }),
        __metadata("design:type", String)
    ], ActionHistory.prototype, "comment", void 0);
    ActionHistory = __decorate([
        Entity({
            singularName: 'Action',
            pluralName: 'Actions',
            endpoint: ɵ0,
            baseUrl: ɵ1,
            allItemsProperty: 'results',
            separateArrayParams: true,
            parseDataQuery: ActionBaseEntity.convertActionCenterQueryToParams,
            parseData: ɵ2,
        })
    ], ActionHistory);
    return ActionHistory;
}(ActionBaseEntity));
export { ActionHistory };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
